#Camada_1 {
  height: 700px;
  width: 700px;
}
.brazil-map {
  fill: #AFD4E4;
  stroke: black;
}
/* #BR-RR {
  fill: #425380
}
#BR-AC {
  fill: #425380
}
#BR-RO {
  fill: #425380
}
#BR-MS {
  fill: #456386;
}
#BR-TO {
  fill: #425380;
}
#BR-PI {
  fill: #466587;
}
#BR-CE {
  fill: #4E8693;
}
#BR-PE {
  fill: #4E8693;
}
#BR-SE {
  fill: #456085;
}
#BR-AL {
  fill: #456085;
}
#BR-BA {
  fill: #52979A;
}
#BR-GO {
  fill: #4D8291;
} */
#BR-MG {
  fill: #597d98;
}
/* #BR-RJ {
  fill: #90C2CB;
}
#BR-ES {
  fill: #486B89;
} */
#BR-SP {
  fill: #884948
}
/* #BR-PR {
  fill: #52979A;
}
#BR-SC {
  fill: #4E8793;
}
#BR-RS {
  fill: #52979A;
} */

.graphic-map {
  height: 21px;
  background: #AFD4E4;
  background: -webkit-gradient(linear, left top, right top, from(#AFD4E4), color-stop(#486B89), to(#B22400));
  background: -webkit-linear-gradient(left, #AFD4E4, #486B89, #B22400);
  background: linear-gradient(90deg, #AFD4E4, #486B89, #B22400);
}
.graphic-map-legends p:nth-child(1){
  margin-left: 0;
}
.graphic-map-legends p{
  margin-left: 16%;
}
.graphic-map-legends {
  display: flex;
}
.date-map {
  margin-top: 3%;
  display: flex;
  margin-left: 30%;
}
.date-map p:nth-child(1){
  margin-left: 0;
}
.date-map p {
  color: #cccccc;
  padding: 10px 0;
  margin-left: 2.5%;
  padding-right: 2.5%;
  border-right: 1px solid #CCCCCC;
}
.date-map p.active {
  color: #313131;
  cursor: default !important;
  background: #D2D2D2;
  margin-left: 0;
  border-left: 3px solid #666666;
  border-right: 3px solid #666666;
  padding-left: 2.5%;
}
.date-map p:hover {
  cursor: not-allowed;
}

@charset "UTF-8";
/*GLOBAL*/
/*variaveis*/
/*ESTADOS*/
:root {
  --success: #89B218;
  --successBackground: #D9E6D8;
  --danger: #DB6969;
  --padrao: #45C0F5;
  --verdeAzul: #13B2B9;
  --azul: #006591;
  --azulEscuro: #034A69;
  --cinzaIcon: #7C7C7C;
  --accent-color: #C8700A;
  --cinza: #898989;
  --border: #bfbfbf;
  --whiteCinza: #f2f2f2;
  --bgWhite: #fcfcfc;
  --textCinza: #797979;
  --black: #383838;
  --pink: #ef98ef ;
}

/*MIXINS*/
/*OVERRIDE THEME*/
.highcharts-credits {
  display: none !important;
}

.card-header {
  color: #006591 !important;
  border-bottom: none;
}

label {
  color: #006591;
  padding-left: 5px;
}

.form-group label {
  padding-left: 0 !important;
}

label.form-group {
  padding-left: 0 !important;
}

body {
  font-family: "Open Sans", sans-serif;
}

p {
  color: #383838;
  font-size: 14px;
}

h1 {
  font-size: 1.3rem;
}

ul:not(.style-list) {
  list-style-type: none;
  padding-left: 0px;
}

b,
strong {
  font-weight: 600;
}

.text-normal {
  font-size: 13px;
}
.text-normal a,
.text-normal i {
  color: #006591;
}

.radius-5-0 {
  border-radius: 5px 0px 0px 5px;
}

.radius-0-5 {
  border-radius: 0px 5px 5px 0px;
}

.badge-unavailable {
  background: rgba(0, 0, 0, 0.2);
}

.btn-padrao {
  font-size: 12px;
  color: white !important;
  font-weight: 600;
  letter-spacing: 0.4px;
}
.btn-padrao:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0.7;
}
.btn-padrao .form-group {
  font-size: 13px;
}

.bg-success {
  background: #89B218 !important;
}

.bg-verde {
  background: #13B2B9;
}

.bg-padrao {
  background: #45C0F5;
}

.bg-amarelo {
  background: #f5ad5a;
}

i.link {
  text-decoration: none !important;
}

.link {
  text-decoration: underline;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.link:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: #45C0F5 !important;
}

.btn-invisible {
  background: transparent !important;
  border: none;
}

.progress {
  height: 18px !important;
}

.bold {
  font-weight: 600;
}

.verde {
  color: #13B2B9 !important;
}

.azul {
  color: #006591 !important;
}

.azulP {
  color: #45C0F5 !important;
}

.cinza {
  color: #7C7C7C !important;
}

.wrong {
  color: #DB6969 !important;
}

.right {
  color: #89B218 !important;
}

.accent-color {
  color: #C8700A !important;
}

.icon-color {
  color: #829FAC !important;
}

.ama {
  color: #f5ad5a !important;
}

.white {
  color: white !important;
}

.success {
  color: #89B218 !important;
}

.danger {
  color: #DB6969 !important;
}

.black {
  color: #383838;
}

.text-danger {
  color: #DB6969 !important;
}

.warning-hover:hover {
  background: #DB6969 !important;
}
.warning-hover:hover i {
  color: white !important;
}

.verde-hover:hover {
  background: #13B2B9 !important;
}
.verde-hover:hover i {
  color: white !important;
}

.clickable {
  cursor: pointer;
}

/*SIZES*/
.w-4 {
  min-width: 4em;
}

.padding-15 {
  padding-left: 30px;
  padding-right: 30px;
}

/*SEARCH INPUT*/
.bg-cinza {
  background: #F7F7F7;
}

.btnFiltro {
  background: #45C0F5;
  color: #fff;
  text-align: left;
  border: none;
  border-radius: 0px !important;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

/* FORMS */
.required label:after {
  content: "*";
  color: #DB6969;
  text-align: right;
}

.has-error label {
  color: #DB6969 !important;
}

/*File input style*/
.fileContainer {
  background: #45C0F5;
  border-radius: 0.4em;
  color: #fff;
  float: left;
  padding: 0.5em;
  width: 163px;
  height: 40px;
}
.fileContainer [type=file] {
  background: #45C0F5;
  color: #fff;
  border: none;
  font-size: 14px;
  font-weight: bold;
  display: none;
  padding: 0.5em;
}
.fileContainer:hover {
  background: #23607a;
  cursor: pointer;
}

/*NAVICONS*/
.navbar-links {
  display: inline-flex !important;
  color: #006996;
  font-size: 14px;
  padding: 30px 0 30px 0;
  border: 1px solid #95989a;
  width: 100%;
  text-align: center;
}

.icons {
  text-align: center;
}
.icons:hover {
  color: #45C0F5;
  cursor: pointer;
}

.expand-icon {
  width: 11%;
}

/*NAVBTN*/
.btn-align {
  display: flex;
}
.btn-align .ml-2 {
  color: #fff !important;
}

.btn-white {
  background: white !important;
  border: 1px solid #bfbfbf;
  color: #383838 !important;
  font-size: 12px;
}
.btn-white:hover {
  color: #006591 !important;
}
.btn-white:focus {
  color: #006591 !important;
}

.dropdown {
  display: inline-flex;
  margin-right: 5px;
  margin-left: 5px;
  vertical-align: top;
}

/*Card Style*/
main .card {
  border-radius: 17px;
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
main .card:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

/*BREADCRUMBS*/
.breadcrumb {
  border-radius: 0px !important;
  padding-top: 0px;
  padding-bottom: 0px;
}

.bg-breadcrumb {
  border-bottom: 1px solid #bfbfbf;
  background: transparent;
  margin-bottom: 25px;
}

.bg-breadcrumb--modal .breadcrumb-item {
  color: #fff !important;
}

.breadcrumb-item {
  padding: 10px 0 10px 0;
  font-size: 14px;
  color: #034A69;
  font-weight: 400;
  display: inline-flex;
}

.breadcrumb-active.breadcrumb-item:before {
  content: "/" !important;
}

.no-pointer {
  cursor: default !important;
}
.no-pointer:hover {
  cursor: default !important;
}

.breadcrumb-link {
  padding: 10px 0 10px 0;
  font-size: 14px;
}
.breadcrumb-link a {
  color: #034A69 !important;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}
.breadcrumb-link a:hover {
  color: #23607a !important;
  font-size: 14px;
  text-decoration: none;
}

.breadcrumb-active {
  color: #23607a !important;
  padding: 10px 0 10px 0;
  font-size: 14px;
}

.breadcrumb-active-nobackslash {
  color: #23607a !important;
  padding: 10px 0 10px 0;
  font-size: 14px;
}

.bg-breadcrumbElaborador {
  background: #e6f1f2 !important;
}

.breadcrumb-linkElaborador {
  padding: 10px 0 10px 0;
}
.breadcrumb-linkElaborador a {
  color: #939999 !important;
  font-size: 14px;
  text-decoration: none;
}
.breadcrumb-linkElaborador a:hover {
  color: #23607a !important;
  font-size: 14px;
  cursor: pointer;
}

.breadcrumb-activeElaborador {
  color: #23607a !important;
  padding: 10px 0 10px 0;
  font-size: 14px;
}

/*Circle Graph*/
.circle-graph-line {
  width: 190px;
  height: 190px;
  border-radius: 100px;
  background: transparent;
  border: 3px solid #45C0F5;
  vertical-align: middle;
  padding: 17px;
}

.circle-graph {
  width: 150px;
  height: 150px;
  border-radius: 100px;
  background: #45C0F5;
  vertical-align: middle;
}

.labelGraph {
  color: #fff;
  font-size: 40px;
  margin-bottom: 0;
  font-weight: 200 !important;
}

.labelGraph-p {
  color: #fff;
}

.input[type=radio] {
  margin-left: -0.4rem !important;
  margin-top: 0px;
}

.inputfile {
  border-radius: 0px !important;
}

.form-control:disabled {
  background: #f9f9f9 !important;
}

/*CARDS*/
/* card-tags */
.card-tag {
  background: #f7f7f7;
  margin: 0 1.25rem 0 1.25rem;
}
.card-tag h4 {
  padding-bottom: 5px;
  border-bottom: 3px solid #eeeeee;
}

/* card-reference */
.card-reference {
  padding: 10px 20px;
  border-radius: 14px;
}
.card-reference.view {
  min-height: 10rem;
  max-height: 10rem;
  min-width: 350px;
  max-width: 350px;
  margin: 10px 15px;
  overflow: scroll;
}
.card-reference.card-comp {
  background: #006591;
}
.card-reference.card-cog {
  border-radius: 14px;
  background: #034A69;
}
.card-reference.head {
  min-width: 350px;
  margin: 10px 15px;
}
.card-reference.hability {
  border-radius: 14px;
  overflow: scroll;
  background: #13B2B9;
}
.card-reference h5 {
  padding-top: 20px;
  font-size: 16px;
  color: #fff;
  text-align: right;
}

.card-reference-body {
  text-align: right;
}
.card-reference-body h5 {
  text-align: center;
}
.card-reference-body.center {
  text-align: center;
}

.card-reference p {
  color: #fff;
  font-size: 14px;
}

/* BUTTONS */
.primary-button {
  background: #45C0F5;
  color: #fff;
  font-size: 12px;
  border: none;
}
.primary-button:hover, .primary-button:active {
  background: #23607a;
  color: #fff;
}

.default-button {
  border: 1px solid #d0d0d0;
  background: #fff;
  color: #383838;
  font-size: 14px;
  text-align: center;
}
.default-button:hover {
  background: #034A69;
  border: 1px solid #fff;
}
.default-button:hover span {
  color: #fff !important;
}

.danger-button {
  background: #DB6969;
  font-size: 12px;
  border: none;
  color: #fff;
}
.danger-button:hover {
  background: #DB6969;
  opacity: 0.7;
}

.selected-option {
  background: #e1e1e1 !important;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.success-background {
  background: #D9E6D8 !important;
  background: var(--successBackground) !important;
}

.btn-number {
  min-width: 50px !important;
}

.btn-secondary:hover {
  color: #006591;
}
.btn-secondary:focus, .btn-secondary:active {
  box-shadow: unset !important;
  color: #006591;
}

.btn-nobackgorund {
  background: transparent;
}
.btn-nobackgorund i {
  float: none !important;
}

.btn-actions {
  background: #fff;
  border: 1px solid #898989;
}

/* FORM TABLES */
.form-table {
  border-radius: 10px;
  background: #fff;
  padding: 0 10px 0 10px;
}
.form-table > thead > tr > th {
  font-size: 14px;
}
.form-table > tbody > tr > th,
.form-table > tbody > tr > td {
  font-size: 14px;
}
.form-table > thead > tr > th {
  font-weight: bold;
  border-top: none;
}

.bg-white {
  background: #fff !important;
}

.table-total {
  font-weight: bold !important;
}

/* DETAILS */
.details {
  border-radius: 10px;
  border: 1px solid #d6d6d6;
  border-left: 6px solid #45C0F5 !important;
  cursor: default !important;
}
.details .card-header {
  border-radius: 10px;
  color: #45C0F5;
  font-weight: bold;
  font-size: 16px;
  padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  border-bottom: none;
}
.details .card-body {
  border-radius: 0 0 10px 10px;
}
.details .card-header:hover {
  color: #23607a;
  cursor: pointer;
}

/* DROPDOWN TRANSITION */
#dropdown-content {
  position: relative;
  top: 0;
}

.item-section {
  padding: 20px 5px 10px 5px;
  border: 1px solid #bfbfbf;
  border: 1px solid var(--border);
  min-height: 130px;
  margin-bottom: 20px;
  border-radius: 4px;
  background: white !important;
}
@media screen and (max-width: 769px) {
  .item-section {
    min-height: 260px;
    max-height: 100%;
  }
}

.collapse-section {
  max-width: 93% !important;
  margin: auto;
  margin-left: 59px;
}
.collapse-section .nav-link {
  font-size: 14px !important;
  color: #006591 !important;
  color: var(--azul) !important;
  font-weight: 400 !important;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.collapse-section .nav-link:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-color: transparent;
  color: #45C0F5 !important;
  color: var(--padrao) !important;
}
.collapse-section .nav-link.active {
  border-color: #bfbfbf #bfbfbf #fcfcfc;
  border-color: var(--border) var(--border) var(--bgWhite);
  background: transparent;
}
.collapse-section .tab-content > .active .card-body {
  border-bottom: 1px solid #bfbfbf;
  border-bottom: 1px solid var(--border);
  border-left: 1px solid #bfbfbf;
  border-left: 1px solid var(--border);
  border-right: 1px solid #bfbfbf;
  border-right: 1px solid var(--border);
}
.collapse-section .card-body {
  font-size: 13px;
}

.nav-expand {
  position: absolute;
  width: 100%;
  z-index: 1;
}
.nav-expand .navbar-links {
  background: #fff;
}

/* MODAL */
.bg-breadcrumb--modal {
  background: #034A69;
  padding-top: 10px;
  padding-bottom: 10px;
}
.bg-breadcrumb--modal li {
  font-weight: 400;
}

.modal-lg {
  margin-top: 2%;
  max-width: 900px !important;
}

.modal-content ol.bg-breadcrumb {
  padding-top: 5px;
  padding-bottom: 5px;
}

.modal-body {
  background: #f7f7f7;
  padding-top: 0px;
  max-height: 790px;
  overflow: scroll !important;
}

.bg-white {
  background: white;
  overflow: auto;
}

.modal-header {
  padding-top: 0px;
  padding-bottom: 0px;
  color: #F7F7F7 !important;
  background: #006591;
  border-radius: 4px 4px 0 0;
}
.modal-header h5 {
  padding-top: 5px;
  font-size: 18px !important;
  margin-bottom: 0;
}
.modal-header h5,
.modal-header span {
  color: white !important;
}

.modal-footer {
  padding-top: 9px;
  padding-bottom: 9px;
}

.card-header {
  background: #F7F7F7;
  color: #006591;
}

.card {
  border: 1px solid #d6d6d6;
  background: #F7F7F7;
}

.card-body {
  border-radius: 4px !important;
}
.card-body.no-radius-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-width: 1px !important;
}
.card-body img {
  max-width: 100%;
}

.cardInput {
  background: #ffffff;
  margin-top: 20px;
  font-size: 16px;
}
.cardInput .col-1 {
  padding-right: 7px;
  border-right: 1px solid #e2e2e2;
}

.listCheckBox li {
  font-size: 20px;
  color: #006591;
  font-weight: 600;
  text-align: center;
}
.listCheckBox li:first-child {
  font-size: 20px;
  color: #034A69;
  font-weight: 600;
  text-align: left;
}

.cardInput .col-11 p {
  padding-top: 10px;
  font-size: 16px;
  color: #383838;
  font-weight: 300 !important;
}

/* OVERFLOW HORIZONTAL */
.overflow-horizontal .base-horizontal {
  overflow-x: auto;
  overflow-y: visible;
}
.overflow-horizontal .base-horizontal .row {
  display: flex;
  flex-wrap: nowrap;
}

/* PAGINATION */
.pagination {
  display: inline-block;
  padding-left: 0;
  float: right;
  border-radius: 4px;
}
.pagination > li {
  display: inline;
}
.pagination > li > button {
  position: relative;
  float: right;
  padding: 6.5px 10px;
  line-height: 1;
  text-decoration: none;
  color: #006591;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: right;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #45C0F5;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  z-index: 2;
  color: #0ca1e2;
  background-color: #eee;
  border-color: #ddd;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 3;
  color: #fff;
  background-color: #45C0F5;
  border-color: #45C0F5;
  cursor: default;
}
.pagination > .disabled > span {
  color: #777;
  background-color: #fff;
  border-color: #ddd;
  cursor: not-allowed;
}
.pagination > .disabled > span:hover, .pagination > .disabled > span:focus {
  color: #777;
  background-color: #fff;
  border-color: #ddd;
  cursor: not-allowed;
}
.pagination > .disabled > a {
  color: #777;
  background-color: #fff;
  border-color: #ddd;
  cursor: not-allowed;
}
.pagination > .disabled > a:hover, .pagination > .disabled > a:focus {
  color: #777;
  background-color: #fff;
  border-color: #ddd;
  cursor: not-allowed;
}

.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
}
.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}
.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}
.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

/*Matriz de Referencia*/
.desativar {
  display: inline-flex;
  float: right;
  max-width: 130px;
  min-width: 130px;
}
.desativar p {
  margin-right: 10px;
  font-size: 12px;
  color: #898989;
  margin-top: 3px;
  max-width: 70px;
  min-width: 70px;
}

/* LISTS */
.lista-normal {
  list-style: disc !important;
  padding-left: 20px !important;
}
.lista-normal li {
  font-size: 14px !important;
  color: #383838;
}

.button-basic {
  font-size: 14px;
}
.button-basic:hover {
  opacity: 0.7;
}

.dropdown-button {
  background: var(--white);
  min-width: 100px;
  max-width: 100%;
  margin-left: 15px;
  font-size: 14px;
  vertical-align: top;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
}
.dropdown-button .dd-header-title {
  min-width: 100%;
}
.dropdown-button .dd-header-title:after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-button span {
  position: relative;
  top: 5px;
}
.dropdown-button:hover {
  color: #006591;
  color: var(--azul);
}

.dropdown-list {
  list-style-type: none;
  background: var(--white);
  border: 1px solid #bfbfbf;
  border: 1px solid var(--border);
  position: absolute;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #898989;
  color: var(--cinza);
  text-align: left;
  background-clip: padding-box;
  border-radius: 0.25rem;
  margin-top: 7px;
  margin-left: -12px;
}
.dropdown-list li {
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 5px;
  z-index: 999;
  text-align: center;
  border-bottom: 1px solid #bfbfbf;
  border-bottom: 1px solid var(--border);
  padding-bottom: 10px;
}
.dropdown-list li a {
  color: #898989 !important;
  color: var(--cinza) !important;
}
.dropdown-list li a:hover {
  color: #13B2B9 !important;
  color: var(--verdeAzul) !important;
  text-decoration: none;
}

.dados-gerais-style {
  border: 1px solid #bfbfbf;
  border: 1px solid var(--border);
  border-left: 8px solid #45C0F5;
  border-left: 8px solid var(--padrao);
  border-radius: 14px 4px 4px 14px;
  margin-bottom: 20px;
}
.dados-gerais-style .card-header.closed {
  border-radius: 6px 4px 4px 6px !important;
}
.dados-gerais-style .dadosGerais {
  padding: 0px;
}
.dados-gerais-style .dadosGerais .card-header {
  border-radius: 6px 4px 0px 0px;
  font-size: 14px;
  font-weight: 600;
  color: #006591;
  color: var(--azul);
}
.dados-gerais-style .dadosGerais p {
  font-size: 14px;
}
.dados-gerais-style .dadosGerais p :first-child {
  font-weight: 600;
}

.label-text {
  font-size: 12px !important;
}
.label-text dt {
  font-size: 14px;
  font-weight: 600;
  color: #006591;
  color: var(--azul);
  padding-bottom: 10px;
}

.label-horizontal {
  display: inline-flex;
  font-size: 12px;
  list-style: none;
}
.label-horizontal li {
  margin-right: 15px;
  color: #797979;
  color: var(--textCinza);
}
.label-horizontal li span {
  font-weight: 600;
  color: #383838;
  color: var(--black);
}

.list-item-detail {
  max-width: 50px !important;
  border-right: 1px solid #898989;
  border-right: 1px solid var(--cinza);
}
@media screen and (max-width: 769px) {
  .list-item-detail {
    border-right: none;
    position: relative;
    top: -15px;
    right: 5px;
  }
}

.status-tag {
  color: #fff;
  padding-top: 5px;
  text-align: center;
  margin: 0 !important;
  height: 36px;
  min-width: 100%;
  border-radius: 4px;
  float: right;
}

.item {
  padding: 20px 5px 10px 5px;
  border: 1px solid #bfbfbf;
  border: 1px solid var(--border);
  min-height: 130px;
  margin-bottom: 20px;
  border-radius: 4px;
  background: white;
}
@media screen and (max-width: 769px) {
  .item {
    min-height: 260px;
    max-height: 100%;
  }
}

.item-competencia {
  padding: 20px 5px 10px 5px;
  border: 1px solid #bfbfbf;
  min-height: 110px;
  margin-bottom: 20px;
  border-radius: 4px;
  background: white;
}
.item-competencia .number {
  font-weight: 600;
}
.item-competencia .list-data-text p {
  text-overflow: unset;
  width: 100%;
  overflow: auto;
  white-space: unset;
}
.item-competencia h3 {
  margin-bottom: 10px !important;
}
.item-competencia b {
  color: #383838;
  color: var(--black);
}
@media screen and (max-width: 769px) {
  .item-competencia {
    min-height: 260px;
    max-height: 100%;
  }
}

.checkbox-input-item {
  max-width: 50px !important;
}
@media screen and (max-width: 769px) {
  .checkbox-input-item {
    border-right: none;
    position: relative;
    top: -15px;
    right: 5px;
  }
}

.resposta {
  margin-bottom: 20px;
  padding: 0px 15px;
}
.resposta p {
  margin: 0;
  padding-top: 7px;
}

.numeros {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0px 15px;
}
.numeros label,
.numeros .input-group-text {
  font-size: 14px;
}
.numeros .input-group-text {
  background: #eeeeee;
  border-color: #eeeeee;
  font-size: 14px;
}
.numeros input {
  font-size: 14px;
}

.no-overflow {
  overflow: unset !important;
}

.Toastify__toast-body p {
  color: white !important;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ball-status {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.print-loading {
  text-align: center;
  font-size: 14px;
  color: #006591;
}

@media print {
  main::after {
    content: "Copyright site.com";
    display: block;
    text-align: center;
  }

  header,
footer {
    display: none !important;
  }

  #printheader {
    display: none;
  }

  body,
div {
    display: block !important;
  }

  @page {
    margin: 1cm;
  }
  .navbar,
.nav-header,
.footer,
.no-print {
    visibility: hidden;
  }

  .no-print-d-none {
    display: none;
  }

  .iten-print {
    page-break-inside: avoid;
    page-break-after: always;
  }

  #printable {
    visibility: visible;
    overflow: visible !important;
    position: fixed;
    left: 0;
    top: 0;
  }
  #printable * {
    visibility: visible;
    overflow: visible !important;
  }
  #printable ul,
#printable img,
#printable p,
#printable table,
#printable figure,
#printable .dont-cut {
    page-break-inside: avoid;
  }
}
/*FLEX*/
.content {
  display: flex;
}

@media print {
  .print-component {
    display: table;
  }
}

/*PAGES*/
.number-itens {
  margin-bottom: 20px;
}
.number-itens p {
  float: right;
  background: #45C0F5;
  font-size: 13px;
  padding: 5px 7px;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  vertical-align: middle;
  max-width: 100px;
  margin: auto;
  display: block;
  position: static;
}

.filter-size {
  max-width: 100px;
  font-size: 13px;
  color: #006591 !important;
  max-height: 33px !important;
}
.filter-size .Select-control, .filter-size .Select-multi-value-wrapper {
  height: 31px !important;
  max-height: 31px !important;
  padding-left: 0px;
}
.filter-size .Select-value-label {
  margin-bottom: 5px;
}

.pagination {
  display: flex !important;
}
.pagination a {
  font-size: 12px;
  color: #006591 !important;
  cursor: pointer;
}
.pagination .disabled a {
  background: #f2f2f2;
  color: #898989 !important;
}
.pagination .next a {
  font-weight: 600;
}
.pagination .active a {
  background: #034A69;
  border-color: #034A69;
  color: #fff !important;
}
.pagination .active a:hover {
  background: #006591;
  border-color: #006591;
}

.filtros-avancados b {
  color: #006591;
  vertical-align: middle;
  font-size: 13px;
}
.filtros-avancados ul {
  list-style: none;
  display: inline-flex;
}
.filtros-avancados ul li {
  font-size: 13px;
  background: #45C0F5;
  padding: 5px;
  margin: 3px;
  color: #f2f2f2;
  text-transform: capitalize;
  border-radius: 3px;
}

.Select {
  min-width: 150px;
  float: none;
  margin-right: 5px;
  font-size: 12px;
  max-height: 32px !important;
}
.Select .Select-control, .Select .Select-multi-value-wrapper, .Select .Select-input {
  max-height: 32px !important;
  height: 32px !important;
}
.Select .Select-placeholder {
  color: #383838;
  text-align: left !important;
  top: -2px;
}

.acoes-toolbar .btn {
  min-height: 36px;
}
.acoes-toolbar .filtros-avancados b {
  color: #006591;
  vertical-align: middle;
  font-size: 13px;
}
.acoes-toolbar .filtros-avancados ul {
  list-style: none;
  display: inline-flex;
}
.acoes-toolbar .filtros-avancados ul li {
  font-size: 13px;
  background: #45C0F5;
  padding: 5px;
  margin: 3px;
  color: #f2f2f2;
  text-transform: capitalize;
  border-radius: 3px;
}

.modal-filtros {
  padding: 0;
}

.modal-body-inside {
  padding: 15px;
  background: white;
}
.modal-body-inside label {
  font-size: 14px !important;
}
.modal-body-inside .col-md-12 {
  margin-top: 10px;
}
.modal-body-inside .input-group-text {
  background: #F7F7F7;
  border-color: #EEEEEE;
  font-size: 14px;
}
.modal-body-inside .input-group input {
  font-size: 14px;
}

.modal-separator {
  background: #F7F7F7;
  padding-top: 10px;
  padding-bottom: 7px;
  color: #006591;
  font-size: 14px;
  padding-left: 15px;
}
.modal-separator h5 {
  margin-bottom: 0;
}

.tab-modal {
  cursor: pointer;
  margin-left: 0px;
  margin-top: 20px;
  color: #006591;
  font-size: 14px;
  background: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.tab-modal:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-top: 2px solid #45C0F5 !important;
  color: #45C0F5;
}
.tab-modal.active {
  border-top: 2px solid #45C0F5 !important;
  background: white;
}
.tab-modal:focus {
  border-top: 2px solid #45C0F5 !important;
  box-shadow: none !important;
}

.login-bg {
  background: #fff;
}
.login-bg .box-form {
  margin-top: 12%;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
}
.login-bg .logo-login {
  max-width: 180px;
  text-align: center;
  margin: auto;
  float: none;
  display: block;
}
.login-bg .info-modulo span {
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
  color: #034A69;
}
.login-bg .info-modulo .title-modulo {
  color: #006591;
}
.login-bg .alert {
  margin-top: 2%;
  font-size: 12px;
  text-align: left;
}
.login-bg .alert .close {
  padding: 0.5rem 1rem;
}
.login-bg .form-sign {
  margin-bottom: 30px;
}
.login-bg .form-sign p {
  padding-top: 30px;
  color: #006591;
}
.login-bg .form-sign .group-input {
  position: relative;
}
.login-bg .form-sign .group-input i {
  position: absolute;
  left: 20px;
  font-size: 15px;
  line-height: 95px;
  color: #585858;
}
.login-bg .form-sign .group-input input {
  padding-left: 40px;
  height: 40px;
  font-size: 16px;
  border-radius: 10px;
  color: #898A8B;
  font-weight: 600;
  border-width: 2px;
  background: transparent;
}
.login-bg .form-sign .group-input input::-webkit-input-placeholder {
  font-size: 12px;
}
.login-bg .form-sign .group-input input::-moz-placeholder {
  font-size: 12px;
}
.login-bg .form-sign .group-input input::-ms-input-placeholder {
  font-size: 12px;
}
.login-bg .form-sign .group-input input::placeholder {
  font-size: 12px;
}
.login-bg .form-sign .group-input input:focus {
  border-color: #DB6969;
  border-color: #89B218;
  border-color: #E59E00;
  border-color: #45C0F5;
  border-width: 2px;
  box-shadow: none;
  outline: 0 none;
}
.login-bg .form-sign .group-input input:focus.has-error {
  border-color: #DB6969;
  border-width: 2px;
  box-shadow: none;
  outline: 0 none;
}
.login-bg .form-sign .group-input input.has-error {
  border-color: #DB6969;
  border-width: 2px;
}
.login-bg .form-sign .group-input small {
  font-size: 12px;
  text-align: left;
}
.login-bg .form-sign .group-input small.has-error {
  color: #DB6969;
}
.login-bg .form-sign .action-login {
  display: flex;
  justify-content: space-between;
}
.login-bg .form-sign .custom-control-label {
  font-size: 15px !important;
  color: #585858;
  line-height: 25px;
  font-weight: 400;
}
.login-bg .recuperar-senha {
  border-top: 1px solid #034A69;
  padding-top: 20px;
}
.login-bg .recuperar-senha a {
  color: #006591;
  border-bottom: 1px solid #034A69;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  font-weight: 600;
  cursor: pointer;
}
.login-bg .recuperar-senha a:hover {
  text-decoration: none;
  color: #45C0F5;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.login-2 .box-form {
  border-bottom: none;
  margin-top: 40px;
  max-width: 640px;
}
.login-2 .fa-lock {
  margin-right: 5px;
}
.login-2 h2,
.login-2 h3 {
  font-weight: 600;
}
.login-2 h2 {
  font-size: 24px;
  color: #034A69;
}
.login-2 h3 {
  font-size: 18px;
  color: #034A69;
}
.login-2 .logo-login {
  margin-bottom: 50px;
}
.login-2 p {
  font-size: 14px;
  color: #898989;
}
.login-2 .qr-code {
  border: 1px solid #898989;
  width: 225px;
  max-width: 100%;
  z-index: 9999;
}
.login-2 .login-instructions {
  background: #fff;
  padding: 20px;
  margin-bottom: 10px;
  font-size: 14px;
}
.login-2 .login-instructions .download {
  border-left: 2px solid #fff;
  text-align: center !important;
}
.login-2 .login-instructions .download a {
  color: #45C0F5 !important;
  line-height: 110px;
  text-decoration: underline;
  font-weight: 600;
}
.login-2 .login-instructions h3 {
  font-size: 14px;
  color: #45C0F5;
  font-weight: 600;
}
.login-2 .login-instructions p span {
  color: #45C0F5;
  font-weight: 600;
}
.login-2 .login-instructions p a {
  color: #45C0F5;
  text-decoration: underline;
}
.login-2 .form-sign {
  margin-top: 0;
}
.login-2 label {
  display: inline;
}

.text-spacing {
  letter-spacing: 1px;
}

@media (max-width: 769px) {
  p {
    text-align: center;
  }

  h3,
h2 {
    text-align: center;
    padding-top: 10px;
  }

  img {
    margin: auto;
    display: block;
  }

  .login-instructions {
    text-align: center;
  }
  .login-instructions .download {
    border-left: none !important;
    padding-bottom: 20px;
  }
  .login-instructions .download a {
    line-height: 10px !important;
  }

  .btn {
    margin-top: 0px !important;
  }
}
.matriz-referencia.card {
  border: none !important;
}

.matriz-referencia.card .card-body {
  background-color: #FFFFFF;
}

.Select--multi .Select-value {
  background-color: #ebf5ff;
  background-color: rgba(0, 126, 255, 0.08);
  border-radius: 4px;
  border: 1px solid #e6f2ff;
  color: #034A69;
  color: var(--azulEscuro);
}

.card-body label {
  font-weight: 600;
}

.matrizes .card-body .row .mt-4:first-child {
  position: -webkit-sticky;
  position: sticky;
  z-index: 999;
  left: 0;
}
.matrizes .view:not(.hability):not(.card-cog):first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 999;
}

.eixo, .competencia, .habilidades {
  padding: 20px;
}

.eixo h5, .competencia h5, .habilidades h5 {
  font-size: 16px;
}

.eixo {
  background: #034A69;
}
.eixo h5, .eixo p {
  color: #fff;
}

.competencia {
  background: #006591;
}
.competencia h5, .competencia p {
  color: #fff;
}

.habilidades {
  background: #13b2b9;
  color: #fff;
}

.hab-tag {
  border-radius: 10px;
  padding: 30px;
  background: #438182;
  display: -webkit-flex;
}
.hab-tag span {
  font-size: 26px;
  border-right: 1px solid #fff;
  padding-right: 10px;
}
.hab-tag p {
  margin-left: 20px;
  margin-top: 7px;
  color: white;
}

.background-educat {
  background: #bfbfbf;
  border-radius: 4px;
}

.form-configuracoes {
  padding-bottom: 50px;
  /*CHECKBOX */
}
.form-configuracoes label {
  font-size: 14px !important;
}
.form-configuracoes .display-flex {
  display: flex !important;
  justify-content: flex-end !important;
}
.form-configuracoes .toolbar {
  background: white;
  margin-bottom: 15px;
  margin-top: 15px;
}
.form-configuracoes .toolbar .card-header {
  padding-top: 30px;
  padding-bottom: 20px;
}
.form-configuracoes .toolbar .primary-button:hover {
  color: white;
  background: #006591;
}
.form-configuracoes .jss4.jss2 {
  color: #45C0F5;
}
.form-configuracoes .border-right {
  max-width: 50px;
}

.table-configuracoes {
  border-radius: 10px !important;
}
.table-configuracoes thead {
  background: #45C0F5;
  color: #f2f2f2;
  border-radius: 10px !important;
}
.table-configuracoes thead tr {
  border-radius: 10px !important;
}
.table-configuracoes thead tr th {
  font-weight: 400 !important;
}
.table-configuracoes tbody tr {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.table-configuracoes tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}
.table-configuracoes tbody tr:hover {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background: white;
}
.table-configuracoes tbody tr .switch {
  float: left !important;
}

.dashboard-main h3 {
  font-size: 20px;
  color: #006591;
  margin-bottom: 10px;
}
.dashboard-main progress {
  background: #89B218;
}
.dashboard-main .home-card {
  background: #ffffff;
  min-height: 430px;
  margin-top: 20px;
  border-radius: 8px;
  border: 1px solid #F7F7F7;
}
.dashboard-main .home-card h4 {
  background: #006591;
  color: #ffffff;
  font-size: 16px;
  padding: 10px 0 10px 0;
  border-radius: 8px 8px 0px 0px;
}
.dashboard-main .out-line {
  border: 1px solid #95989A;
  border-radius: 50%;
  height: 105px;
  width: 105px;
}
.dashboard-main .areas-conhecimento p {
  margin-top: 10px;
  margin-bottom: 0;
  color: #006591;
}
.dashboard-main .areas-conhecimento .progress-bar, .dashboard-main .areas-conhecimento .progress {
  min-height: 20px;
}
.dashboard-main .circle-chart-total {
  max-width: 100px;
}
.dashboard-main .circle-chart-total .CircularProgressbar-text {
  color: #45C0F5;
}
.dashboard-main .circle-chart-total p {
  margin-top: 5px;
  color: #45C0F5;
}

.ruler {
  height: 10px;
  display: flex;
}

.ruler.top {
  border-top: 1px solid #649006;
}

.ruler.bottom {
  border-bottom: 1px solid #649006;
}

.ruler .cm {
  height: 10px;
  border-right: 1px solid #649006;
  padding-right: 5px;
}

.ruler .cm:nth-child(1) {
  padding-right: 2px;
}

.ruler .mm {
  height: 5px;
  border-right: 1px solid #649006;
  padding-right: 5px;
}

.list-navigation-dashboard {
  list-style-type: none;
  display: inline-flex;
  background: #E6F1F2;
  padding: 0px;
}

.list-navigation-dashboard li {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: 400;
  border-right: 1px solid #A5C2C4;
  color: #313131;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.list-navigation-dashboard .active {
  background: #A5C2C4;
  color: #fff;
  margin: 0px !important;
  line-height: 60px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.list-navigation-dashboard a:hover {
  text-decoration: none;
}

.list-navigation-dashboard li:nth-child(4) {
  border-right: none;
}

.table-lista-encomendas {
  background: none;
}
.table-lista-encomendas thead {
  border-bottom: none !important;
}
.table-lista-encomendas thead th {
  padding-left: 0;
  border-bottom: none !important;
  padding-bottom: 2px;
  color: #383838;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding-right: 20px !important;
}
.table-lista-encomendas tbody td {
  font-size: 12px !important;
  border-top: none !important;
  text-align: left;
  padding-left: 0;
  padding-top: 0;
}

.card-alone {
  max-width: 93% !important;
  margin: auto;
  margin-left: 59px;
}
.card-alone .nav-item {
  cursor: pointer;
}

.professores-first {
  border-bottom: 1px solid #bfbfbf;
  padding: 0;
  margin-bottom: 20px;
}
.professores-first p {
  font-size: 13px !important;
}
.professores-first .subtitle {
  color: #006591;
  font-weight: 600;
  margin-bottom: 5px !important;
}

.professores-table table {
  border: none !important;
}
.professores-table table tr {
  border: none !important;
}
.professores-table table th {
  border: none !important;
}

/*MODAL*/
.table-modal thead th {
  font-size: 14px;
  font-weight: 400;
  color: #034A69;
  border: 1px solid #D8D8D8;
  border-bottom: 2px solid #034A69 !important;
}
.table-modal tbody td {
  border: 1px solid #D8D8D8;
  font-size: 12px;
  color: #383838;
  padding-top: 20px;
  padding-bottom: 20px;
}
.table-modal tbody td a {
  color: #006591;
  font-weight: 700 !important;
  text-decoration: underline;
}

.recharts-label tspan {
  padding-top: 10px;
  color: #006591;
}

.card-blocos {
  overflow: auto;
  border: none !important;
}
.card-blocos .card-header {
  background: #006591 !important;
  color: white;
  padding: 5px 15px;
}
.card-blocos .card-header h5 {
  color: white;
  font-size: 16px;
  margin-top: 5px !important;
  vertical-align: bottom;
}
.card-blocos .card-body {
  border: 0.5px solid #d6d6d6;
}
.card-blocos .btn-white {
  background: transparent !important;
  color: white !important;
  border-color: transparent;
}

.deactivated {
  border: 1px solid #45C0F5 !important;
  background: white !important;
  color: #45C0F5 !important;
}

.regua-pro {
  font-size: 14px;
  color: #006591;
  text-align: center;
}
.regua-pro a {
  color: #006591;
  font-weight: 600;
}
.regua-pro .below-ruler {
  border-top: none;
  padding-top: 5px;
}
.regua-pro .ruler-cm {
  padding: 10px 0px 5px 0px;
  border-bottom: none;
  padding-bottom: 5px;
}
.regua-pro .ruler-cm .ruler {
  border-top-color: #006591;
}
.regua-pro .ruler-cm .mm, .regua-pro .ruler-cm .cm {
  border-right-color: #006591 !important;
}
.regua-pro th {
  border-top: 0;
  padding: 8px;
}

.lista-pre-teste {
  display: inline-flex;
}
.lista-pre-teste li {
  margin-right: 20px;
  color: #383838;
  font-size: 13px;
}
.lista-pre-teste li span:first-child {
  font-weight: 600;
}

.body-dados img {
  max-width: 100%;
}
.body-dados h3 {
  color: #034A69;
  font-size: 14px;
  margin: 20px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #006591;
}
.body-dados h3 i {
  margin-right: 10px;
  font-size: 20px !important;
}
.body-dados h3 .btn {
  letter-spacing: unset !important;
  font-size: unset !important;
}
.body-dados .body-dados--habilidades p {
  color: #383838;
}
.body-dados .body-dados--habilidades p:nth-child(1) {
  margin-top: 30px;
  text-transform: uppercase;
}
.body-dados .body-dados--habilidades p:nth-child(2) {
  margin-bottom: 6px;
}
.body-dados .body-dados--habilidades p:nth-child(3) {
  color: aqua;
}
.body-dados .text-name {
  color: #006591;
  font-size: 25px;
}
.body-dados .text-name .fas {
  margin: 0 15px;
  font-size: 20px !important;
}
.body-dados .text-name span:nth-child(2) {
  font-size: 15px;
  vertical-align: middle;
}
.body-dados .text-occupation {
  color: #006591;
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.body-dados .btn {
  margin-top: 30px;
}
.body-dados .btn-disabled {
  margin-top: 20px;
  border: 1px solid #006591 !important;
  background: #fff;
  cursor: default !important;
}
.body-dados .btn-disabled span {
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.body-dados .btn-disabled span:nth-child(2) {
  font-weight: 800 !important;
  letter-spacing: 1px;
}
.body-dados .body-dados--contato {
  color: #006591;
  font-size: 14px;
}
.body-dados .body-dados--contato p {
  font-weight: 600;
  letter-spacing: 1px;
}
.body-dados .body-dados--contato p row {
  margin-top: 30px;
  font-size: 13px;
  margin-bottom: 20px;
}
.body-dados .body-dados--contato p span {
  font-weight: 400;
}
.body-dados .body-dados--contato .text-default-blue {
  color: #383838;
  font-weight: 400;
}

.btn-link {
  color: #006591;
}

.form-sign {
  margin-bottom: 30px;
}
.form-sign p {
  padding-top: 30px;
  color: #006591;
}
.form-sign .group-input {
  position: relative;
}
.form-sign .group-input i {
  position: absolute;
  left: 15px;
  top: -7px;
  font-size: 15px;
  line-height: 95px;
  color: #006591;
}
.form-sign .group-input input {
  padding-left: 40px;
  height: 40px;
  font-size: 14px;
  border-radius: 10px;
  color: #006591;
  font-weight: 600;
  border-width: 2px;
  background: transparent;
}
.form-sign .group-input input::-webkit-input-placeholder {
  font-size: 12px;
}
.form-sign .group-input input::-moz-placeholder {
  font-size: 12px;
}
.form-sign .group-input input::-ms-input-placeholder {
  font-size: 12px;
}
.form-sign .group-input input::placeholder {
  font-size: 12px;
}
.form-sign .group-input input:focus {
  border-color: #45C0F5;
  border-width: 2px;
  box-shadow: none;
  outline: 0 none;
}
.form-sign .group-input input:focus.has-error {
  border-color: #DB6969;
  border-width: 2px;
  box-shadow: none;
  outline: 0 none;
}
.form-sign .group-input input.has-error {
  border-color: #DB6969;
  border-width: 2px;
}
.form-sign .group-input small {
  font-size: 12px;
  text-align: left;
}
.form-sign .group-input small.has-error {
  color: #DB6969;
}
.form-sign .action-login {
  display: flex;
  justify-content: space-between;
}
.form-sign .custom-control-label {
  font-size: 15px !important;
  color: #585858;
  line-height: 25px;
  font-weight: 400;
}

.table-analise {
  background: white;
  border-radius: 4px;
  border: 1px solid #D6D6D6 !important;
}
.table-analise thead th {
  font-size: 13px;
  color: #5a5a5a;
  border: 1px solid #D6D6D6;
}
.table-analise thead th:first-child {
  border-right: none !important;
}
.table-analise thead th:nth-child(2) {
  border-left: none !important;
}
.table-analise tbody td {
  font-size: 13px;
  color: #797979;
  border: 1px solid #D6D6D6;
  padding: 20px 10px;
  vertical-align: middle;
}
.table-analise tbody td:first-child {
  border-right: none !important;
}
.table-analise tbody td:nth-child(2) {
  border-left: none !important;
}

.table-alternativa-analise thead th:first-child {
  border-right: 1px solid #D6D6D6 !important;
}
.table-alternativa-analise thead th:nth-child(2) {
  border-left: 1px solid #D6D6D6 !important;
}
.table-alternativa-analise tbody td:first-child {
  border-right: 1px solid #D6D6D6 !important;
  font-weight: 600 !important;
}
.table-alternativa-analise tbody td:nth-child(2) {
  border-left: 1px solid #D6D6D6 !important;
}

.checkbox-input {
  max-width: 50px !important;
}
.checkbox-input label {
  padding-top: 12px !important;
}
@media screen and (max-width: 769px) {
  .checkbox-input {
    border-right: none;
    position: relative;
    top: -25px;
    right: 5px;
  }
}

.indice-vermelho {
  font-size: 16px;
  color: #DB6969 !important;
  margin-bottom: 20px;
}

.indice-success {
  font-size: 16px;
  color: #89B218 !important;
  margin-bottom: 20px;
}

.border-dados {
  border: 1px solid #006591;
  background: #fcfcfc;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  padding-top: 5px !important;
  padding-bottom: 10px !important;
  border-radius: 4px;
}
.border-dados .titulo-dados {
  font-size: 16px !important;
}

.def-groups {
  display: flex;
  align-items: center;
}
.def-groups .placeholder {
  text-align: center;
  padding: 30px;
}

.icono-grupos {
  width: 60px;
  height: 60px;
  color: white;
  font-size: 1.5em;
  border-radius: 50px;
  align-items: center !important;
  justify-content: center !important;
  padding-top: 18px;
}

.grupo-title {
  color: #383838;
  font-size: 14px;
  margin-top: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.highcharts-container {
  margin: auto;
}

.nav-link-v2 {
  margin-right: 15px;
  font-weight: 600;
  color: #898989;
  padding-bottom: 5px;
}
.nav-link-v2:hover {
  color: #45C0F5;
  text-decoration: none;
}

.active-v2 {
  color: #006591 !important;
  border-bottom: 2px solid #45C0F5;
}

/* TABLE ETAPA INICIAL & FINAL */
.table-etapa {
  border: 1px solid #D6D6D6;
}
.table-etapa thead th {
  border: 1px solid #D6D6D6;
  background: #fcfcfc;
  color: #006591;
  font-size: 14px;
}
.table-etapa tbody td {
  border: 1px solid #D6D6D6;
  color: #383838;
  font-size: 14px;
  background: white;
}
.table-etapa tbody td:first-child {
  color: #006591;
  font-weight: 600;
}

.anulado-tri {
  background: #fcfcfc !important;
  text-align: center;
  font-weight: 600;
}

.acoes-print {
  background: #034A69;
  padding: 20px;
}

.header-print {
  border: 2px solid #006591;
  border-radius: 4px;
}

.situacao-problema {
  text-align: left !important;
}
.situacao-problema .numero-situacao {
  color: #006591;
  font-size: 20px;
  font-weight: 600;
}
.situacao-problema .meta-print {
  color: #898989;
}
.situacao-problema .comando-resposta {
  margin-bottom: 20px;
  margin-top: 20px;
}
.situacao-problema h3, .situacao-problema .numero-situacao {
  text-align: left !important;
}
.situacao-problema p {
  text-align: left !important;
  font-size: 12pt;
  margin: 0;
}

.remove-inline-css {
  color: #383838 !important;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 12pt !important;
  font-family: sans-serif !important;
}
.remove-inline-css p, .remove-inline-css p span, .remove-inline-css span font {
  color: #383838 !important;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 12pt !important;
  font-family: sans-serif !important;
}

.short-long-option {
  font-size: 14px;
  font-weight: bold;
}

.white-card {
  background: #e6e6e6;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.item-graph {
  margin-bottom: 20px;
  text-align: center;
}

.item-graph p {
  font-size: 14px;
  color: #383838;
  font-weight: 500;
}

.header-itens-total {
  background: #45C0F5;
  color: #fff;
  padding: 20px 10px;
  margin-bottom: 20px;
}

.header-itens-total h4 {
  font-size: 22px;
}

.header-itens-total h5 {
  border-right: 1px solid #fff;
  font-size: 16px;
  margin-top: 5px;
}

.header-itens-total .fa {
  font-size: 20px;
  margin-top: 5px;
}

.header-calibrados-total {
  background: #bcbc12;
  color: #fff;
  padding: 10px;
  margin-bottom: 20px;
}

.header-calibrados-total h4 {
  font-size: 22px;
  margin-top: 10px;
}

.header-calibrados-total h5 {
  border-right: 1px solid #fff;
  font-size: 16px;
  margin-top: 15px;
}

.header-calibrados-total .fa {
  font-size: 20px;
  margin-top: 15px;
}

.header-calibrados-total label {
  display: none;
}

.box-calibrados {
  border: 1px solid #c6c6c6;
  text-align: center;
  padding: 0 10px;
  color: #383838;
  margin-top: 20px;
}

.box-calibrados p {
  margin-top: 10px;
  font-weight: 300;
}

.box-calibrados h3 {
  font-size: 46px;
}

.box-calibrados .skill-list {
  margin-top: 20px !important;
}

.box-calibrados .skill-list p {
  top: -3px;
}

.box-calibrados progress {
  height: 30px !important;
}

.questionario .pergunta {
  /*padding: 5px;*/
  /*border: 1px #ccc solid;*/
}

.questionario h5 {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 600;
}

.questionario table {
  margin-bottom: 30px;
}

.questionario .pergunta p {
  margin-bottom: 0;
  /*border: 1px #ccc solid;*/
}

.analise-psicometrica-toolbar button {
  margin-right: 10px;
  border-radius: 0.25rem !important;
}

.revisao-card .revisao-card-title {
  font-size: 18px;
  text-transform: uppercase;
  color: #23607a;
  font-weight: 600;
}

.not-found {
  padding: 40px 0;
}
.not-found h2 {
  margin-bottom: 20px;
}
.not-found h4 {
  font-size: 16px;
  margin-bottom: 20px;
}
.not-found .btn-link {
  padding-left: 0;
}

div.tooltip {
  position: absolute;
  text-align: center;
  padding: 0.2rem;
  background: #313639;
  color: #F9F9F9;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
  font-size: 0.7rem;
}

svg {
  display: block;
  width: 100%;
  height: 500px !important;
  background: "white";
  overflow: visible;
}

.highcharts-container {
  margin: auto;
  max-height: auto !important;
}

.highcharts-credits {
  display: none !important;
}

svg.highcharts-root {
  margin-top: -10px;
  height: auto !important;
}

svg rect.selection {
  stroke: none;
}

.container__background {
  border-radius: 10px;
  border: 1px solid #000;
  background: #3c4583;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr;
}

.basic__info {
  display: flex;
  flex-direction: column;
}

.first__info {
  font-weight: bold;
}

.first__info > span {
  font-weight: 500;
}

.input__background {
  padding: 0.7rem;
  margin: 0.9rem;
  border-radius: 10px;
  background: #fff;
}

span {
  font-size: 0.8rem;
}

@media screen and (max-width: 769px) {
  .container__background {
    display: flex;
    flex-direction: column;
  }
}
.skill-text {
  margin-left: 1rem;
  font-weight: 500;
  text-align: justify;
}

.axis__text {
  padding: 0.8rem;
  border-radius: 8px;
  background: #fff;
  display: flex;
}

.test {
  width: 100%;
  height: 200px !important;
  padding: 1.2rem 1rem 2rem 1.3rem;
  height: auto;
  overflow: visible;
}

.test rect.selection {
  stroke: none;
}

.teaching__field {
  padding: 1rem;
  font-family: "Open Sans", sans-serif;
  border-radius: 8px;
  margin-top: 0.5rem;
}

.matriz {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #3c4583;
}

.school__field {
  background: #d9d9d9;
  padding: 1rem;
  font-family: "Open Sans", sans-serif;
  border-radius: 8px;
  margin-top: 1rem;
}

.table-proficiency {
  border-radius: 15px 15px 0 0;
  margin-top: 1rem;
  padding: 1rem;
  background: #d9d9d9;
  text-align: center;
  width: 100%;
}

span {
  text-align: justify;
}

.proeficiency__infos {
  display: flex;
  margin-top: 0.5rem;
  justify-content: space-between;
}

.axis__infos {
  padding: 1.2rem;
  border-bottom: 2px solid #e2e2e2;
  border-top: 2px solid #e2e2e2;
  border-left: 2px solid #e2e2e2;
  width: 100%;
  border-radius: 10px 0 0 10px;
  background: #f5f5f5;
}

.graphic {
  margin-left: 1rem;
  width: 55%;
  height: auto;
  padding: 1.2rem;
  border-bottom: 2px solid #e2e2e2;
  border-top: 2px solid #e2e2e2;
  border-right: 2px solid #e2e2e2;
  border-radius: 0 10px 10px 0;
  background: #f5f5f5;
}

.graphic_circular {
  margin-left: 1rem;
  width: 55%;
  height: 200px !important;
  padding: 1.2rem;
  border-bottom: 2px solid #e2e2e2;
  border-top: 2px solid #e2e2e2;
  border-right: 2px solid #e2e2e2;
  border-radius: 0 10px 10px 0;
  background: #f5f5f5;
}

.box__mt {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.infos__school {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
}

h2 {
  font-size: 1rem;
  font-weight: bold;
}

.example-hability {
  font-size: 0.9rem;
}

.box {
  padding: 1.5rem;
}

hr {
  width: auto;
  background: #d9d9d9;
  border: 2px solid #d9d9d9;
}

.pro-hr {
  margin-top: 0.1rem;
}

.school__material {
  margin-top: 0.9rem;
  font-weight: bold;
  color: #3c4583;
  text-align: center;
}

.school__material > span {
  font-size: 1.3rem;
}

.habilidade {
  display: flex;
  margin-top: 1rem;
  padding: 0.8rem;
  border-radius: 8px;
  background: #fff;
}

@media screen and (max-width: 769px) {
  .proeficiency__infos {
    display: flex;
    flex-direction: column;
  }

  .axis__infos {
    padding: 1.2rem;
    border-right: 2px solid #e2e2e2;
    border-bottom: none;
    border-top: 2px solid #e2e2e2;
    border-left: 2px solid #e2e2e2;
    width: 100%;
    border-radius: 10px 10px 0 0;
    background: #f5f5f5;
  }

  .graphic {
    margin: 0 !important;
    margin-top: 0.5rem !important;
    width: 100%;
    height: auto;
    padding: 1.2rem;
    border-left: 2px solid #e2e2e2;
    border-top: none;
    border-right: 2px solid #e2e2e2;
    border-radius: 0 0 10px 10px;
    background: #f5f5f5;
  }

  .skill-text {
    margin-left: 1rem;
    font-weight: 500;
    text-align: justify;
  }
}
/*COMPONENTS*/
.box-card {
  border: 1px solid #b2b2b2;
  text-align: center;
  margin: 5px;
  min-height: 140px;
  -webkit-animation: boxanimation 2s;
}

@-webkit-keyframes boxanimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes boxanimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.primary-card {
  background: #45C0F5;
  color: #fff;
}

.dash-2 {
  background: #088D97;
  color: #fff;
  height: 550px;
  border-radius: 3px;
}

.secundary-card {
  background: #A83D24;
  color: #fff;
}

.circle-inf {
  margin-top: 2.5rem;
}

@-webkit-keyframes scaleexpand {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  80% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scaleexpand {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  80% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.circle-background {
  -webkit-animation: scaleexpand 0.6s;
  text-align: center;
  background: #CB4727;
  width: 80px;
  height: 80px;
  padding-top: 17px;
  font-size: 30px;
  border-radius: 50%;
  margin-left: 25px;
}

.group-3 .text-center .circle-background {
  margin-left: 38%;
}

.group-5 .text-center .circle-background {
  margin-left: 28%;
}

.updates {
  background: #45C0F5;
  color: #005B8B;
  min-height: 300px;
  max-height: 300px;
  border-radius: 3px;
  overflow: scroll;
}
.updates label {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #005B8B;
  color: #fff;
  font-size: 14px;
  font-weight: 200;
}

.nav-logos {
  margin: 15px 0 15px 0;
}

.nav-user {
  margin: 15px 0 15px 0;
  background: #fff;
  color: #829FAC;
  float: right;
}

.logo-client {
  height: 2rem;
  margin-top: 15px;
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #ebebeb;
}

.nav-user p {
  margin-bottom: 0 !important;
}

.img-user {
  border-radius: 50%;
  margin-left: 10px;
  height: 50px;
  width: 50px;
  margin-top: 3px;
}

.notice-user {
  margin-top: 7px;
  padding-left: 10px;
  color: #829FAC;
  border-left: 1px solid;
  background: transparent;
  border-left: 1px solid #829FAC !important;
  border-radius: 0;
  border: none;
}
.notice-user:hover {
  color: #6e7f86;
  background: transparent;
}

.dropdown-item {
  cursor: pointer;
}
.dropdown-item .btn {
  font-size: 15px !important;
  text-align: left;
  color: #006591 !important;
}
.dropdown-item:active {
  background-color: transparent !important;
}

.dropdown-nav {
  margin-top: 7px;
  padding-left: 10px;
  color: #829FAC;
  border-left: 1px solid;
  background: transparent;
  border-radius: 0;
  border: none;
  cursor: pointer;
}
.dropdown-nav:active {
  box-shadow: unset;
}
.dropdown-nav:focus {
  box-shadow: unset;
  color: #006591;
}
.dropdown-nav:hover {
  color: #006591 !important;
}
.dropdown-nav .user-name:after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.555em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-nav:hover {
  color: #6e7f86;
  background: transparent;
}
.dropdown-nav:active {
  background: transparent !important;
}
.dropdown-nav:focus {
  background: transparent !important;
}
.dropdown-nav a {
  color: #006591;
  cursor: pointer;
}

.user-name {
  padding: 0 10px 0 10px;
  margin-top: 3px;
}

.user-align {
  padding-left: 6%;
}

@-webkit-keyframes bell-buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
}
@keyframes bell-buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
}
.bell-buzz {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
}
.bell-buzz:hover, .bell-buzz:focus, .bell-buzz:active {
  -webkit-animation-name: bell-buzz;
  animation-name: bell-buzz;
  -webkit-animation-duration: 0.15s;
  animation-duration: 0.15s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@media screen and (max-width: 768px) {
  .logo-client {
    border-left: none;
    margin-top: 10px;
  }
}
.menu-config .dropdown-menu {
  top: -2px !important;
  padding: 0;
  border: none !important;
  border-radius: 0px 0px 3px 3px;
  box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.25);
  padding-top: 10px !important;
  padding-bottom: 12px !important;
  z-index: 99;
}
.menu-config .dropdown-menu a, .menu-config .dropdown-menu span {
  margin: 0;
  font-size: 13px;
  text-transform: uppercase;
  color: #383838;
  min-width: 100%;
  padding: 8px 20px !important;
  letter-spacing: 1px !important;
  font-weight: 600;
}
.menu-config .dropdown-menu a:hover, .menu-config .dropdown-menu span:hover {
  background: rgba(86, 207, 226, 0.22);
  color: #006591;
  text-decoration: none;
}
.menu-config .dropdown-menu .disabled {
  color: #898989 !important;
  cursor: default !important;
}
.menu-config .dropdown-menu .disabled:hover {
  background: rgba(0, 0, 0, 0.1);
}
.menu-config .dropdown-menu .divider-menu {
  cursor: default;
}
.menu-config .dropdown-menu .divider-menu:hover {
  background: transparent !important;
}
.menu-config .dropdown-menu .children-menu {
  font-size: 12px;
  padding-left: 30px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-weight: 400 !important;
  letter-spacing: 1.2px !important;
  text-transform: none !important;
}

.teste {
  overflow: hidden;
  -webkit-animation: scale 0.6s ease 1;
}

.circle-border {
  margin-left: 35px;
}
.circle-border h5 {
  border-radius: 50%;
  min-height: 80px;
  width: 80px;
  padding-top: 15%;
  border: 1px solid #fff;
  font-size: 36px;
  color: #005B8B;
  -webkit-animation: opacitytitle 2s;
}

@-webkit-keyframes opacitytitle {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes opacitytitle {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes scale {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.status {
  padding: 0.5rem 0 0.5rem 0;
  margin-top: 0.5rem;
  background: #37A6D6;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  border-top: 1px solid #005B8B;
  -webkit-animation: heightexpand 0.6s;
}
.status label {
  font-size: 16px;
  font-weight: 200;
}
.status p {
  color: #005B8B;
  font-size: 30px;
}

@-webkit-keyframes heightexpand {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}

@keyframes heightexpand {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
.animated-title > .card-body > h5 {
  -webkit-animation: positiontransform 2s;
}

@-webkit-keyframes positiontransform {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes positiontransform {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
.cardbody.dash-2.animated-title > h5 {
  -webkit-animation: positionright 2s;
}

@-webkit-keyframes positionright {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes positionright {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
.vertical-animation {
  overflow: hidden;
}
.vertical-animation > .card-body > h5 {
  -webkit-animation: verticalanimation 2s;
}

@-webkit-keyframes verticalanimation {
  from {
    -webkit-transform: translateY(-200%);
            transform: translateY(-200%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes verticalanimation {
  from {
    -webkit-transform: translateY(-200%);
            transform: translateY(-200%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.list-data {
  padding-left: 10px;
}
.list-data .form-check-input {
  margin-right: 20px;
}

.preview-content {
  display: flex;
}

.list-data-title h3 {
  font-weight: 600;
  font-size: 16px;
  color: #23607a;
  margin-bottom: 0;
  text-transform: capitalize;
}
.list-data-title small {
  color: #797979;
  font-size: 14px;
}

.list-data-text {
  margin-top: 10px;
  color: #606060;
}
.list-data-text p {
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 300;
}

.meta-descip {
  font-size: 10px;
  color: #898989;
  margin-top: 0px;
}
.meta-descip i {
  margin-right: 10px;
}
.meta-descip a {
  margin-left: 10px;
  color: #034A69;
  color: var(--azulEscuro);
  text-decoration: underline;
  font-weight: 600;
}
.meta-descip span {
  font-weight: 600;
}

/* custom check */
.container-check-custom {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.container-check-custom:first-child {
  padding-top: 3px;
}
.container-check-custom input[type=checkbox] {
  position: absolute;
  top: 5px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 5px;
  left: 0px;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: 1px solid #bfbfbf;
}

/* On mouse-over, add a grey background color */
.container-check-custom:hover > input ~ .checkmark {
  background-color: #ccc;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
}
.container-check-custom input:checked ~ .checkmark {
  background-color: #006591;
}

/* When the checkbox is checked, add a blue background */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-check-custom input:checked ~ .checkmark:after {
  display: block;
}
.container-check-custom .checkmark:after {
  left: 5px;
  top: 2px;
  width: 8px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Style the checkmark/indicator */
/*INPUT*/
input.form-control {
  border-radius: 10px;
  border-color: #EEEEEE;
}

.form-group {
  font-size: 14px;
}
.form-group input, .form-group textarea, .form-group .Select-control {
  border-radius: 10px;
  border-color: #EEEEEE;
}

.form-input .addon-right, .form-input .addon-left {
  background: #EEEEEE;
  padding: 9px 15px 0 15px;
  color: #777777;
}

.addon-right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.addon-left {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

label.form-control-label {
  font-weight: bold;
}

.input-multi-title {
  font-size: 14px;
  font-weight: bold;
}
.input-multi-title ~ label {
  font-size: 12px;
  font-weight: bold;
}
.input-multi-title ~ input.form-control {
  display: inline;
  width: auto;
}

.no-label {
  margin-top: 0.5rem;
}

input.form-control.has-error {
  border: 1px solid #dc3545;
}

.non-default-input .form-control {
  border-radius: 4px;
  border-color: #c6c6c6;
  font-size: 12px;
  height: 33px;
}

.has-error .Select-control {
  border-color: #DB6969;
}

.checkbox-input-item {
  max-width: 50px !important;
}
@media screen and (max-width: 769px) {
  .checkbox-input-item {
    border-right: none;
    position: relative;
    top: -15px;
    right: 5px;
  }
}

.actions-header {
  background: #f2f2f2;
  background: var(--whiteCinza);
  border-radius: 4px;
  border: 1px solid #bfbfbf;
  border: 1px solid var(--border);
  padding-top: 30px;
  padding-bottom: 25px;
  margin-bottom: 10px;
}
@media screen and (max-width: 790px) {
  .actions-header .checkmark {
    margin-bottom: 15px;
    position: absolute;
    top: 0;
  }
  .actions-header .btn {
    margin: 10px 5px;
    float: left;
    margin-left: 15px;
  }
}

.list-item-base {
  padding: 20px 5px 10px 5px;
  border: 1px solid #bfbfbf;
  background: white;
  min-height: 130px;
  margin-bottom: 20px;
  border-radius: 4px;
}
@media screen and (max-width: 769px) {
  .list-item-base {
    min-height: 260px;
    max-height: 100%;
  }
}

.collapse-conteudo {
  max-height: 300px;
  overflow: hidden;
}

.collapse-itens {
  min-height: 300px;
}

.btn-trimmed {
  border: 1px solid #797979;
  z-index: 9999;
}

.text-options button:not(.note-color-btn) {
  background: #fff !important;
}
.text-options .note-toolbar {
  background-color: #f5f5f5 !important;
  border-bottom: 1px solid #ccc;
}
.text-options .note-icon-caret {
  display: none;
}
.text-options a {
  color: #006591 !important;
  font-size: 13px;
}
.text-options a p, .text-options a pre, .text-options a blockquote, .text-options a h1, .text-options a h2, .text-options a h3, .text-options a h4, .text-options a h5, .text-options a h6 {
  margin-bottom: 4px !important;
  font-size: 19px;
}

.popover {
  display: none;
}

button.close {
  margin: 0 !important;
}

.dropdown-toggle::after {
  margin-left: 15px;
}

.modal-title {
  margin-bottom: 0;
  line-height: 2.5;
  vertical-align: middle;
  color: #006591;
}

.modal-footer .btn-primary {
  background: #45C0F5 !important;
  border: none;
}

.note-editable.panel-body {
  font-family: Arial;
  font-size: 12px;
}

.form-group.has-error .note-editor.note-frame.panel.panel-default {
  border: 1px solid #dc3545;
}

.note-editable {
  height: 100% !important;
}

.fa-rotate-315 {
  -webkit-transform: rotate(315deg);
          transform: rotate(315deg);
}

.note-btn {
  border: 1px solid #ccc;
}

.search {
  max-width: 90%;
  font-size: 13px;
  /*position: relative*/
}
.search ::-webkit-input-placeholder {
  color: #383838 !important;
}
.search ::-moz-placeholder {
  color: #383838 !important;
}
.search ::-ms-input-placeholder {
  color: #383838 !important;
}
.search ::placeholder {
  color: #383838 !important;
}

.search-term {
  font-size: 13px;
  float: left;
  width: 100%;
  border: 1px solid #c6c6c6;
  padding: 5px;
  height: 33px;
  border-radius: 5px;
  outline: none;
  color: #006591 !important;
}
.search-term:focus {
  color: #bebcbf;
}

.search-button {
  position: absolute;
  right: 0;
  width: 40px;
  height: 36px;
  border: 1px solid #c6c6c6;
  border-left: none !important;
  text-align: center;
  color: #bfbfbf;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  font-size: 13px;
}

.wrap {
  width: 100%;
  position: relative;
  top: auto;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@media screen and (max-width: 769px) {
  .wrap {
    position: relative;
    top: auto;
    margin-bottom: 20px;
  }
}
mark {
  padding: 0;
}
mark[class^=check_ortografia] {
  background-color: #fff76c;
}
mark.check_palavra_negativa {
  background-color: #D57A28;
}
mark.check_palavra_proibida {
  background-color: #77C5CA;
}

.item-correction {
  border: 1px solid #f7f7f7;
  margin: 0 20px !important;
}

.item-correction-title {
  color: #23607a;
}

.item-correction-line {
  border: 1px solid #eee;
}

.item-correction-card {
  margin-top: 20px;
}

.alternatives > .check_quantidade_palavras {
  border: 2px solid #cc3939;
  padding: 10px;
}

p.no-errors {
  font-size: 20px;
}

.table-pendencias {
  border: 1px solid #bfbfbf;
  margin-bottom: 50px;
  min-width: 100%;
  background: white;
}
.table-pendencias thead th {
  background: #f2f2f2;
  font-size: 14px;
  color: #006591;
  padding: 10px;
  border-bottom: 1px solid #bfbfbf;
  border-right: 1px solid #bfbfbf;
}
.table-pendencias tbody td {
  max-width: 150px;
  border-bottom: 1px solid #bfbfbf;
  border-right: 1px solid #bfbfbf;
  padding: 10px;
  font-size: 13px;
}

.btn-icon .fa {
  padding-right: 10px;
}

.btn-icon {
  font-weight: normal !important;
}

/* EXCEPTION FOR BTN IN REFERENCES */
.card-reference-body .btn-icon .fa {
  padding-right: 0 !important;
}

.btn.btn-disabled {
  background: #ccc !important;
  opacity: 1;
}
.btn.btn-disabled:hover {
  background: #ccc !important;
  cursor: no-drop;
}

.one {
  background-color: #013A6B;
  background-image: -webkit-linear-gradient(244deg, #034A69 50%, #006591 50%);
  min-height: 500px;
}

.one p {
  position: absolute;
  font-size: 16px !important;
}

.one p:nth-child(1) {
  right: 10%;
  top: 20%;
}

.one p:nth-child(2) {
  top: 65%;
  left: 10%;
}

.hab-select {
  display: flex;
  margin-bottom: 2rem;
  margin-top: 1rem;
  background: #F0F0F0;
  padding: 20px;
  border: 1px solid #E2E2E2;
  border-radius: 10px;
}

.hab-select header {
  text-align: center;
  border-right: 1px solid #e3e3e3;
  padding-right: 10px;
}

.hab-select h4 {
  font-size: 16px;
  margin-bottom: 5px;
}

.hab-select main {
  padding-left: 10px;
}

.hability-tag {
  display: -webkit-flex;
  background: #238383;
  max-width: 100%;
  border-radius: 4px;
  padding-right: 5px;
}

.hability-tag b {
  border-right: 1px solid #54afb7;
  padding: 6px 10px 0 10px;
  color: #fff;
}

.hability-tag p {
  padding-left: 10px;
  padding-top: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 8px;
}

.alternatives {
  list-style: none;
  padding-left: 30px;
  margin-top: 20px;
  height: auto;
}

.alternatives li {
  margin-bottom: 10px;
  position: relative;
  font-size: 14px;
}

.alternatives li:before {
  display: inline-block;
  font-family: FontAwesome;
  position: absolute;
  top: 50%;
  right: calc(100% + 10px);
  font-size: 1.5em;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.alternatives .right:before {
  content: "\F058";
  border: 1px solid transparent;
  border-top-left-radius: 200px;
  border-top-right-radius: 200px;
  border-bottom-left-radius: 200px;
  border-bottom-right-radius: 200px;
  padding-left: 5px;
  padding-right: 5px;
  color: #3c763d;
}

.alternatives .right {
  color: #212529 !important;
}

.alternatives .wrong:before {
  content: "\F057";
  border: 1px solid transparent;
  border-top-left-radius: 200px;
  border-top-right-radius: 200px;
  border-bottom-left-radius: 200px;
  border-bottom-right-radius: 200px;
  padding-left: 5px;
  padding-right: 5px;
  color: #a94442;
}

.alternatives .wrong {
  color: #212529 !important;
}

.nav-tabs .nav-item a.active {
  border-top: 3px solid #45C0F5;
  border-bottom: none;
}

.card-item .card-body {
  border-left: 0.5px solid #dee2e6;
  border-right: 0.5px solid #dee2e6;
  border-bottom: 0.5px solid #dee2e6;
  background-color: #fff;
}

.label-view-horizontal {
  margin-top: 10px;
  color: #555555;
  font-size: 14px;
  padding-left: 0;
}

.label-view-horizontal span {
  font-weight: bold;
}

.label-view-horizontal p {
  margin-bottom: 5px;
}

/* NAVBTN */
.btn-align {
  display: flex;
}

.btn-align .ml-2 {
  color: #fff !important;
}

.nav-group-align {
  display: flex;
}

.nav-group {
  margin-top: 10px;
}

.nav-group label {
  font-weight: bold;
  border-bottom: 2px solid #45C0F5;
}

.nav-group-align a:nth-child(1) {
  padding-left: 0;
  border-left: none;
}

.nav-group-align a {
  padding-right: 1rem;
  padding-left: 1rem;
  border-left: 1px solid #e3e3e3;
}

.nav-group-align a.disabled {
  color: #ccc;
  pointer-events: none;
}

.icons.expand-icon.disabled {
  color: #ccc;
}

.icons.expand-icon.disabled:hover {
  cursor: no-drop !important;
}

/*NAVICONS*/
.navbar-links {
  display: inline-flex !important;
  color: #006996;
  font-size: 14px;
  padding: 30px 0 30px 0;
  border: 1px solid #95989A;
  width: 100%;
  text-align: center;
}

.icons:hover {
  color: #45C0F5;
  cursor: pointer;
}

.icons {
  text-align: center;
}

.expand-icon {
  width: 11%;
}

.updates {
  background: #45C0F5;
  color: #005B8B;
  min-height: 300px;
  max-height: 300px;
  border-radius: 3px;
  overflow: scroll;
  border: none !important;
}

.updates h5:before {
  content: "\F0F3";
  font-family: "FontAwesome";
  color: #005B8B;
  padding-right: 10px;
}

.updates label {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #005B8B;
  color: #fff;
  font-size: 14px;
  font-weight: 200;
}

.popover-cat {
  text-align: center;
  font-weight: bold;
}

.popover-cat h3.popover-header {
  color: #45C0F5 !important;
  background: #fff;
}

.popover.popover-cat {
  border-radius: 0;
}

.popover-cat .popover-body {
  border-top: 1px solid #45C0F5;
  border-bottom: 1px solid #45C0F5;
  padding: 20px 0;
}

.popover-map {
  text-align: center;
  font-weight: bold;
  min-width: 250px;
  color: #444444;
}

.popover-map h3.popover-header {
  color: #45C0F5 !important;
  background: #fff;
  text-align: left;
}

.popover.popover-map {
  border-radius: 0;
}

.popover-map .popover-body {
  padding: 20px 0;
}

.inf-popover-map {
  display: flex;
  border-bottom: 1px solid #45C0F5;
  font-weight: 200;
}

.inf-popover-map p {
  margin-bottom: 0;
}

.inf-popover-map p:nth-child(2) {
  margin-left: 60%;
}

.inf-popover-body {
  display: flex;
  font-weight: 500;
  color: #444444;
}

.inf-popover-body p:nth-child(2) {
  margin-left: 35%;
}

.reference-matrix-list .card-header {
  color: #23607a;
  background: #FFFFFF;
}

.reference-matrix-list .card {
  background: #FFFFFF;
}

.reference-matrix-hab {
  border: 1px solid #707070;
  background: #F7F7F7;
  border-radius: 10px;
  padding: 15px 20px;
  margin: 25px 0;
}

.reference-matrix-hab h5 {
  font-size: 16px;
  font-weight: bold;
  margin-left: 20px;
}

.reference-matrix-hab p {
  font-size: 14px;
}

.reference-matrix-hab .axes h5::before {
  padding-right: 5px;
  content: "\F111";
  font-family: "FontAwesome";
  color: #45C0F5;
}

.reference-matrix-hab .skills h5::before {
  padding-right: 5px;
  content: "\F111";
  font-family: "FontAwesome";
  color: #033849;
}

.reference-matrix-hab .hab h5::before {
  padding-right: 5px;
  content: "\F111";
  font-family: "FontAwesome";
  color: #CE9700;
}

.reference-matrix-hab .skills p,
.reference-matrix-hab .axes p {
  margin: 20px 0 20px 40 px;
}

.reference-matrix-item {
  background: #F7F7F7;
  border: 1px solid #707070;
  padding: 15px;
  margin: 25px 0 25px 0;
  border-radius: 10px;
}

.reference-matrix-item .col-sm-4 label {
  color: #23607a;
  font-size: 16px;
  font-weight: bold;
}

.reference-matrix-item .col-sm-8 dt {
  color: #707070;
}

.reference-matrix-item .col-sm-8 dd {
  color: #606060;
}

.step .step-name.disabled {
  cursor: no-drop;
}

/*STEPS*/
.progress-tracker {
  margin: 0;
  counter-reset: item;
  list-style-type: none;
  padding: 0;
}
.progress-tracker .step {
  margin: 0 auto 20px;
  counter-reset: su;
}
.progress-tracker .step .step-name {
  background: #eee;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: inline-flex;
  margin: 0 0 0 15px;
  height: 35px;
  width: 100%;
  align-items: center;
  padding-left: 30px;
  color: #777777;
  line-height: 1.2em;
  font-size: 14px;
  text-decoration: none;
}
.progress-tracker .step .step-name:hover {
  color: #006591;
}
.progress-tracker .step:before {
  background: #bfbfbf;
  border-radius: 20px;
  color: #fff;
  width: 35px;
  height: 35px;
  font-size: 15px;
  line-height: 1.8em;
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  counter-increment: item;
  content: counter(item) " " !important;
}
.progress-tracker .step.active .step-name {
  color: #333333;
  font-weight: bold;
  background: #ddd;
}
.progress-tracker .step.active:before {
  background: #45C0F5;
}
.progress-tracker .step.done .step-name {
  color: #3c763d;
}
.progress-tracker .step.done:before {
  background: #87db55;
  content: "\2714";
  color: #fff;
}

/*active*/
/*done*/
/* substep */
.sub-step-reset {
  counter-reset: item;
  margin-top: 20px;
}
.sub-step-reset > li {
  display: block;
}

.progress-tracker .sub-step {
  margin: 0 auto 20px;
}
.progress-tracker .sub-step:before {
  background: #aaa;
  border-radius: 20px;
  margin: 1px 0 0 38px;
  color: #FFFFFF;
  content: "";
  font-size: 12px;
  line-height: 1.9em;
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  counter-increment: subitem;
  content: counter(item) "." counter(subitem) " ";
}
.progress-tracker .sub-step .step-name {
  display: inline-flex;
  margin: 1px 0 0 60px;
  height: 35px;
  width: 83%;
  align-items: center;
  padding-left: 20px;
  color: #777777;
  line-height: 1.2em;
  border-radius: 0 20px 20px 0;
  font-size: 12px;
}
.progress-tracker .sub-step.active .step-name {
  color: #333333;
  font-weight: bold;
  background: #FFFFFF;
}
.progress-tracker .sub-step.active:before {
  background: #45C0F5;
}
.progress-tracker .sub-step.completed .step-name {
  color: #3c763d;
}
.progress-tracker .sub-step.completed:before {
  background: #89B218;
  content: "\2714";
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  outline: none;
  float: right;
}

.switch input {
  position: absolute;
  top: -99999px;
  left: -99999px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #13b2b9;
}

input:focus + .slider {
  box-shadow: 0 0 1px #13b2b9;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

.edital-table th {
  color: #fff;
  background: #45C0F5;
  font-weight: 200;
}

.edital-table td {
  background: #fff;
}

.edital-table .table-name {
  font-weight: bold;
}

.edital-table .table-status {
  font-weight: bold;
}

.edital-table .table-status.aprovado {
  color: #28a745;
}

.edital-table .table-status.reprovado {
  color: #AA2E2E;
}

.edital-table .table-status.analise {
  color: #F5C246;
}

tr.positive td {
  background: #C1FFC8;
}

tr.negative td {
  background: #FFB2B2;
}

.status-inf {
  display: flex;
}

.status-inf p:nth-child(1) {
  padding-right: 10px;
}

.status-inf p:nth-child(2) {
  padding-left: 10px;
  border-left: 1px solid #e3e3e3;
}

.status-inf p:nth-child(1):before {
  content: "\F111";
  font-family: "FontAwesome";
  color: #C1FFC8;
  padding-right: 5px;
}

.status-inf p:nth-child(2):before {
  content: "\F111";
  font-family: "FontAwesome";
  padding-right: 5px;
  color: #FFB2B2;
}

.table-default {
  font-size: 12px;
  border: none;
}
.table-default .rt-table {
  background: white;
}
.table-default .rt-table .container-check-custom:first-child {
  margin-top: 9px;
}
.table-default .rt-table .container-check-custom input[type=checkbox] {
  top: 0;
  left: 14px;
}
.table-default .rt-table .checkmark {
  top: 0;
  left: 14px;
}
.table-default .rt-table .rt-thead, .table-default .rt-table thead {
  border: 1px solid #e0e0e0;
  border-bottom-width: 2px;
  box-shadow: none;
  font-size: 13px;
}
.table-default .rt-table .rt-thead .rt-th, .table-default .rt-table .rt-thead th, .table-default .rt-table thead .rt-th, .table-default .rt-table thead th {
  border-right: none;
  color: #00617b;
  border-right: 1px solid #e0e0e0;
  font-weight: 600;
  text-align: left;
  padding: 5px;
  display: flex;
  align-items: center;
}
.table-default .rt-table .rt-thead .rt-th:last-child, .table-default .rt-table .rt-thead th:last-child, .table-default .rt-table thead .rt-th:last-child, .table-default .rt-table thead th:last-child {
  border-right: none !important;
}
.table-default .rt-table .rt-thead .-sort-asc, .table-default .rt-table thead .-sort-asc {
  box-shadow: inset 0 3px 0 0 #00617b;
}
.table-default .rt-table .rt-thead .-sort-desc, .table-default .rt-table thead .-sort-desc {
  box-shadow: inset 0 -3px 0 0 #00617b;
}
.table-default .rt-table .rt-thead:nth-child(2), .table-default .rt-table thead:nth-child(2) {
  border: 1px solid #d5d5d5;
  border-right: none;
  border-top: none;
}
.table-default .rt-table .rt-thead:nth-child(2) .rt-th, .table-default .rt-table thead:nth-child(2) .rt-th {
  border-right: 1px solid #d5d5d5;
}
.table-default .rt-table .rt-tbody, .table-default .rt-table tbody {
  border: 1px solid #d5d5d5;
  border-top: none;
  background: white;
  overflow: visible;
  overflow: initial;
}
.table-default .rt-table .rt-tbody .rt-tr-group, .table-default .rt-table tbody .rt-tr-group {
  border-color: #d5d5d5;
}
.table-default .rt-table .rt-tbody .rt-tr-group .rt-td, .table-default .rt-table .rt-tbody .rt-tr-group td, .table-default .rt-table tbody .rt-tr-group .rt-td, .table-default .rt-table tbody .rt-tr-group td {
  border-color: #d5d5d5;
  padding: 10px 5px;
  display: flex;
  align-items: center;
  color: gray;
  white-space: normal;
}
.table-default .rt-table .rt-tbody .rt-tr-group .rt-td:last-child, .table-default .rt-table tbody .rt-tr-group .rt-td:last-child {
  overflow: visible;
  overflow: initial;
}
.table-default .pagination-bottom .-pagination {
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding: 10px 0;
}
.table-default .pagination-bottom .-pagination .-previous {
  margin-right: 15px;
  flex: 0 1;
}
.table-default .pagination-bottom .-pagination .-previous button {
  padding: 7px 20px;
  text-transform: uppercase;
}
.table-default .pagination-bottom .-pagination .-center {
  position: absolute;
  left: 0;
  color: #9c9c9c;
}
.table-default .pagination-bottom .-pagination .-center .-pageJump {
  color: #9c9c9c;
}
.table-default .pagination-bottom .-pagination .-next {
  flex: 0 1;
  margin-right: 15px;
}
.table-default .pagination-bottom .-pagination .-next button {
  padding: 7px 20px;
  text-transform: uppercase;
}

.plagius .plagius-section {
  margin-bottom: 0;
}
.plagius .timestamp {
  margin-top: -50px;
  float: right;
}
.plagius .check-plagius-btn.btn-link {
  padding: 0;
  margin-bottom: 2.5px;
}

.quiz {
  background-color: #F8F8F8;
  padding: 20px;
  margin: 20px;
}
.quiz h3 {
  font-size: 14px;
  font-weight: 600;
  color: #417389;
}

.quiz-section {
  margin-top: 30px;
  border-radius: 3px;
}
.quiz-section .quiz-questions {
  border: 1px solid #E8E8E8;
  border-bottom: none;
}
.quiz-section h4 {
  font-size: 14px;
  margin-bottom: 15px;
  color: #717171;
  font-weight: 600;
}

.quiz-question {
  padding: 12px;
  background-color: white;
  border-bottom: 1px solid #E8E8E8;
  font-size: 14px;
  color: #5A5A5A;
}
.quiz-question .fa-check-circle {
  color: #8CC676;
}
.quiz-question .fa-times-circle {
  color: #A04C18;
}
.quiz-question .fa-circle {
  color: #B4B4B4;
}

.info-box {
  background-color: #F8F8F8;
  padding: 20px;
  margin: 20px 0;
  border-radius: 3px;
}
.info-box h3.info-box-title {
  font-size: 14px;
  font-weight: 600;
  color: #23607a;
  margin-bottom: 20px;
}

.info-box-item {
  color: #767E81;
}

.info-box-content > span > p {
  margin-bottom: 0;
  color: #767E81;
}

.btn-equation-shortcut {
  font-size: 18px;
  border-radius: 0;
  border: none;
}

.btn-group-equation-shortcut {
  border: 1px solid #ccc;
  background-color: white;
}

p.equation-preview {
  font-size: 20px;
}

.table-student-diagnosis-default {
  border: 1px solid #D9D9D9;
  border-radius: 10px;
}
@media (max-width: 769px) {
  .table-student-diagnosis-default .-pagination .-pageSizeOptions {
    position: static !important;
    position: initial !important;
  }
}
@media (max-width: 490px) {
  .table-student-diagnosis-default .-center {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}
.table-student-diagnosis-default .-header {
  border-radius: 10px;
  box-shadow: 5px 7px 8px 1px rgba(0, 0, 0, 0.15);
}
.table-student-diagnosis-default .rt-thead .-sort-desc:after {
  content: "\25BC";
  position: absolute;
  top: 8px;
  right: 10px;
}
.table-student-diagnosis-default .rt-thead .-sort-asc:before {
  content: "\25B2";
  position: absolute;
  top: 8px;
  right: 10px;
}
.table-student-diagnosis-default .rt-thead .rt-th.-sort-desc {
  box-shadow: none;
  font-weight: 600;
}
.table-student-diagnosis-default .rt-tbody {
  border: none;
  overflow: visible !important;
  overflow: initial !important;
}
.table-student-diagnosis-default .rt-tbody .rt-tr {
  border-radius: 10px;
  align-items: center;
}
.table-student-diagnosis-default .rt-tbody .-odd {
  background: #D9D9D9 !important;
}
.table-student-diagnosis-default .rt-tbody .-even {
  background: #EDEDED;
}
.table-student-diagnosis-default .rt-tbody .rt-td {
  color: #000;
  padding: 10px 10px !important;
  border-color: #ccc;
  overflow: visible;
  white-space: normal;
  display: flex;
  align-items: center;
}
.table-student-diagnosis-default .rt-tbody .rt-td p {
  margin: 0;
}
.table-student-diagnosis-default .rt-tbody .rt-td .column-label {
  display: none;
}
.table-student-diagnosis-default .rt-tbody .rt-td .table-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table-student-diagnosis-default .rt-tbody .rt-td .container-check-custom {
  display: inline;
}

@media all and (max-width: 768px) {
  .table-responsive {
    padding: none;
  }
  .table-responsive .rt-thead .rt-tr {
    display: none;
  }
  .table-responsive .rt-tbody {
    font-size: 13px;
    min-width: inherit !important;
  }
  .table-responsive .rt-tbody .rt-tr {
    display: block;
  }
  .table-responsive .rt-tbody .rt-td {
    max-width: 100% !important;
    width: 100% !important;
    border: none;
    border-bottom: 1px solid #eaeaea;
    position: relative;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    min-height: 29px;
  }
  .table-responsive .rt-tbody .rt-td .responsive-row {
    text-align: left;
    padding-left: 55% !important;
  }
  .table-responsive .rt-tbody .rt-td .table-buttons {
    padding-bottom: 0.5rem;
    display: flex;
    align-items: center;
  }
  .table-responsive .rt-tbody .rt-td .column-label {
    width: 50%;
    display: block;
    position: absolute;
    left: 1rem;
    font-weight: bold;
  }
}
.expanded-rows .rt-tbody .rt-td {
  padding: 1.5rem 1rem !important;
}

.tabela-horario .rt-tbody .rt-tr {
  font-size: 12px;
  color: #F5F5F5;
}

.-btn {
  width: 60% !important;
}

.-next .-btn {
  float: right;
}

.break-line {
  overflow: visible;
  white-space: normal !important;
}

.table-avalia-default {
  border: 1px solid #BCBCBC;
  border-radius: 11px;
}
@media (max-width: 769px) {
  .table-avalia-default .-pagination .-pageSizeOptions {
    position: static !important;
    position: initial !important;
  }
}
@media (max-width: 490px) {
  .table-avalia-default .-center {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}
.table-avalia-default .-header {
  box-shadow: 5px 7px 8px 1px rgba(0, 0, 0, 0.15);
}
.table-avalia-default .rt-thead .rt-th {
  color: black;
  font-weight: 600;
  text-align: left;
  border-color: #E3E3E3;
  border-bottom: 1px solid #E3E3E3;
  overflow: visible !important;
  white-space: normal !important;
  align-items: center;
  background-color: #D9D9D9;
}
.table-avalia-default .rt-thead .-sort-desc:after {
  content: "\25BC";
  position: absolute;
  top: 8px;
  right: 10px;
}
.table-avalia-default .rt-thead .-sort-asc:before {
  content: "\25B2";
  position: absolute;
  top: 8px;
  right: 10px;
}
.table-avalia-default .rt-thead .rt-th.-sort-desc {
  box-shadow: none;
  font-weight: 600;
}
.table-avalia-default .rt-tbody {
  border: none;
  overflow: visible !important;
  overflow: initial !important;
}
.table-avalia-default .rt-tbody .rt-tr {
  align-items: center;
}
.table-avalia-default .rt-tbody .-odd {
  background: #EDEDED;
}
.table-avalia-default .rt-tbody .-even {
  background: #FFFFFF;
}
.table-avalia-default .rt-tbody .rt-td {
  width: auto;
  color: #000;
  padding: 10px 10px !important;
  border-color: #E3E3E3;
  overflow: visible;
  white-space: normal;
  align-items: center;
  justify-content: space-between;
}
.table-avalia-default .rt-tbody .rt-td p {
  margin: 0;
}
.table-avalia-default .rt-tbody .rt-td .column-label {
  display: none;
}
.table-avalia-default .rt-tbody .rt-td .table-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table-avalia-default .rt-tbody .rt-td .container-check-custom {
  display: inline;
}

@media all and (max-width: 768px) {
  .table-responsive {
    padding: none;
  }
  .table-responsive .rt-thead .rt-tr {
    display: none;
  }
  .table-responsive .rt-tbody {
    font-size: 13px;
    min-width: inherit !important;
  }
  .table-responsive .rt-tbody .rt-tr {
    display: block;
  }
  .table-responsive .rt-tbody .rt-td {
    max-width: 100% !important;
    width: 100% !important;
    border: none;
    border-bottom: 1px solid #eaeaea;
    position: relative;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    min-height: 29px;
  }
  .table-responsive .rt-tbody .rt-td .responsive-row {
    text-align: left;
    padding-left: 55% !important;
  }
  .table-responsive .rt-tbody .rt-td .table-buttons {
    padding-bottom: 0.5rem;
    display: flex;
    align-items: center;
  }
  .table-responsive .rt-tbody .rt-td .column-label {
    width: 50%;
    display: block;
    position: absolute;
    left: 1rem;
    font-weight: bold;
  }
}
.expanded-rows .rt-tbody .rt-td {
  padding: 1.5rem 1rem !important;
}

.tabela-horario .rt-tbody .rt-tr {
  font-size: 12px;
  color: #F5F5F5;
}

.-btn {
  width: 60% !important;
}

.-next .-btn {
  float: right;
}

.break-line {
  overflow: visible;
  white-space: normal !important;
}

.input-type-style {
  position: relative;
  margin-top: 2rem;
  margin-bottom: 3rem;
  width: 100%;
}
.input-type-style .input-error {
  outline: none;
  font-size: 0.875rem;
  margin: 0;
  padding: 0.5rem 0;
  border: 0;
  width: 100%;
  transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  background: -webkit-gradient(linear, left top, left bottom, from(#e6646e), to(#e6646e)) center bottom/0 2px no-repeat, -webkit-gradient(linear, left top, left bottom, from(#e6646e), to(#e6646e)) center bottom/100% 1px no-repeat;
  background: -webkit-linear-gradient(#e6646e, #e6646e) center bottom/0 2px no-repeat, -webkit-linear-gradient(#e6646e, #e6646e) center bottom/100% 1px no-repeat;
  background: linear-gradient(#e6646e, #e6646e) center bottom/0 2px no-repeat, linear-gradient(#e6646e, #e6646e) center bottom/100% 1px no-repeat;
}
.input-type-style .input-style,
.input-type-style .dropdown-style {
  outline: none;
  width: 100%;
  font-size: 0.875rem;
  margin: 0;
  padding: 0.5rem 0;
  border: 1px solid #3c4583;
  border-radius: 8px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  position: relative;
}
.input-type-style .autocomplete {
  padding: 0;
}
.input-type-style .filter-order {
  border-radius: 50%;
  margin-top: 6px;
  padding: 0.4rem 0.8rem 0.4rem 0.8rem;
  background: #969696;
  color: #fff;
}
.input-type-style .no-border {
  background: none;
}
.input-type-style .input-style:disabled,
.input-type-style .dropdown-style:disabled,
.input-type-style .input-error:disabled {
  background: none;
  border-bottom: dotted 1px rgba(0, 0, 0, 0.6);
}
.input-type-style .input-label-style {
  display: flex;
  background: #dadada;
  padding: 0.4rem;
  width: 100%;
  position: absolute;
  bottom: 2.5rem;
  border-radius: 50px;
  left: 0;
  height: 50px;
  align-items: center;
  color: #5b5b5b;
}
.input-type-style .filter-label {
  margin-left: 1rem;
  font-size: 1rem;
}
.input-type-style .label-error {
  color: #ff5046 !important;
}
.input-type-style .arrow-select-pointer {
  position: absolute;
  top: 12px;
  right: 5px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
}

.dropdown-style.animation,
.dropdown-style:focus,
.input-style:focus,
.input-style:not([value=""]),
.input-error:focus,
.input-style:not([value=""]) {
  background-size: 100% 2px, 100% 1px;
}

.ui-select {
  width: 100%;
}

select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
}

@-moz-document url-prefix() {
  .ui-select {
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
  }

  .ui-select select {
    width: 110%;
    background-position: right 30px center !important;
    border: none !important;
  }
}
.helper-block {
  display: block;
  position: absolute;
  top: 40px;
}

.input-helper {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.7rem;
  display: block;
}

.text-danger {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.7rem;
  display: block;
}

.radio-group {
  display: flex;
}
.radio-group .radio-input {
  margin-right: 1rem;
}
.radio-group .radio-input label {
  padding-left: 0.5rem;
}

.input-check-type {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.input-check-type input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.input-check-type input:checked ~ .checkmark:after {
  display: block;
}

.input-check-type .checkmark:after {
  left: 4px;
  top: 2px;
  width: 10px;
  height: 10px;
  border: solid #00b897;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.input-radio-type {
  margin-right: 1rem;
  display: block;
  position: relative;
  padding-left: 30px;
  padding-top: 5px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.input-radio-type input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
}

.radiomark {
  margin-top: 5px;
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #ccc;
  border-radius: 50%;
}
.radiomark-error {
  border: 1px solid #ff5046;
  box-shadow: 0 0 15px #ccc;
}

.input-radio-type input:checked ~ .radiomark {
  background-color: #00b897;
}

.radiomark:after {
  content: "";
  position: absolute;
  display: none;
}

.input-radio-type input:checked ~ .radiomark:after {
  display: block;
}

.input-radio-type .radiomark:after {
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
}

.input-file {
  position: absolute;
  bottom: 10px;
  left: 10px;
  opacity: 0;
}

.required::after {
  content: "";
  color: red;
}

.text-area-label {
  top: 0.35rem;
  left: 0;
  position: absolute;
  pointer-events: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.text-area-type-style {
  margin-bottom: 17px;
  position: relative;
  padding-top: 27px;
}

.text-area-style {
  background: no-repeat bottom, 50% calc(100% - 1px);
  background-size: 0 100%, 100% 100%;
  height: 36px;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
  display: block;
  width: 100%;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: transparent;
  background-clip: padding-box;
  box-shadow: none;
  border: none;
  outline: none;
  transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  background: -webkit-gradient(linear, left top, left bottom, from(#00b897), to(#00b897)) center bottom/0 2px no-repeat, -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0.6))) center bottom/100% 1px no-repeat;
  background: -webkit-linear-gradient(#00b897, #00b897) center bottom/0 2px no-repeat, -webkit-linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)) center bottom/100% 1px no-repeat;
  background: linear-gradient(#00b897, #00b897) center bottom/0 2px no-repeat, linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)) center bottom/100% 1px no-repeat;
}

textarea {
  height: auto !important;
  resize: none;
  line-height: 1.428571 !important;
  overflow: auto;
}

.text-area-style:focus {
  background-size: 100% 2px, 100% 1px;
}

.text-area-style:focus ~ .text-area-label {
  color: #208fdc;
}

.radio-group {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
}
.radio-group .radio-label {
  margin-right: 1.5rem;
}
.radio-group .radio-input {
  display: flex;
  align-items: center;
}
.radio-group .radio-input .input-radio-type {
  padding-top: 0;
}
.radio-group .radio-input .input-radio-type .label-input {
  margin-left: 1.4rem;
}
.radio-group .radio-input .input-radio-type .radiomark {
  margin-top: 0;
}

/*CONTAINERS*/
.hamburguer {
  color: white;
  padding: 15px;
  padding-top: 0px !important;
  cursor: pointer;
}

@media screen and (min-width: 769px) {
  .hamburguer {
    display: none !important;
  }
}
.nav-left .nav-item .nav-link:hover {
  color: #fff;
  cursor: pointer;
  margin: 0px;
}

figure {
  margin: 0px;
}

.nav-icon {
  margin-right: 10px;
}

.table-lista-avaliacoes thead th {
  color: #006591;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  border: 1px solid #D6D6D6;
  border-bottom: 2px solid #D6D6D6;
}
.table-lista-avaliacoes tbody td {
  color: #383838;
  font-size: 12px;
  border: 1px solid #D6D6D6;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  vertical-align: middle;
}

.table-sem-border {
  border: none;
}
.table-sem-border thead th:first-child {
  border-left: none;
}
.table-sem-border thead th:last-child {
  border-right: none;
}
.table-sem-border tbody td:first-child {
  border-left: none;
}
.table-sem-border tbody td:last-child {
  border-right: none;
}

.dropdown-educat .dropdown-item {
  color: #006591 !important;
  font-size: 13px;
}
.dropdown-educat .dropdown-item:hover {
  background: #13b2b938;
}

.list-style li {
  font-size: 13px;
  color: #898989;
}

.item-bg {
  background: #eaeaea;
  padding: 5px;
  border-radius: 3px;
}

.pin {
  text-align: center;
  padding: 30px;
  font-size: 25px;
  color: #034A69;
  letter-spacing: 2px;
  font-weight: 600;
}

.secundary-card .circle-graph-line {
  border-color: #6F0101 !important;
}

.secundary-card .circle-graph {
  background: #8A1E12 !important;
}

.circle-graph {
  -webkit-animation: scalenormal 0.6s;
}

@-webkit-keyframes scalenormal {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scalenormal {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.form-modal {
  margin-left: 46rem;
}

.form-modal .modal-content {
  width: 710px !important;
}

.item-card .float-right {
  display: flex;
}

.item-card .float-right .form-group {
  position: absolute;
  top: -18px;
  right: 6%;
}

.item-card .float-right .form-group .form-input {
  width: 60%;
}

.card-bg .card-form {
  background: #fff !important;
  border: 1px solid #bfbfbf;
}
.card-bg .card-form .card-header {
  background: #fff !important;
}
.card-bg .card-form .card-body {
  background: #fff !important;
}
.card-bg .card-header {
  background: #fff !important;
}
.card-bg .card-body {
  background: #fff !important;
}

.card-header {
  border-color: #eeeeee;
}

.card-form {
  border: none;
}

.card-header-form {
  display: -webkit-box;
}

.card-body.card-body-form {
  background: #F7F7F7;
  position: relative;
}

.card-body-form .primary-button {
  margin-left: 0 !important;
  /* AJUST BUTTON INTO THE FORM */
}

.btns-next {
  text-align: right;
}

.nav-select {
  list-style: none !important;
  color: #fff;
}

.icon-view {
  padding: 40px 0 40px 0;
  background: #006996;
}

.icon-view .icons {
  width: inherit;
}

.bg-next {
  background: #3999C3;
  padding: 10px 0 0 20px;
  height: 40px;
}

.bg-back {
  background: #c33939;
  padding: 10px 0 0 20px;
  height: 40px;
}

.bg-home {
  background: #45C0F5;
  padding: 10px 0 0 20px;
  height: 40px;
}

.nav-select-main {
  background: #fff;
}

.display-align {
  border: 1px solid #858585;
}

.display-align .col-2 {
  padding-right: 0;
  padding-left: 0;
}

.nav-select .icon-view .icons.expand-icon.nav-item:hover {
  color: #fff;
  cursor: default;
}

.nav-select .bg-next:hover {
  background: #13526d;
}

.nav-select .bg-back:hover {
  background: #772323;
}

.nav-select .bg-next a:hover,
.nav-select .bg-back a:hover,
.nav-select .bg-home a:hover {
  text-decoration: none;
}

.nav-select .bg-home:hover {
  background: #13526d;
}

.nav-select-main a {
  text-decoration: none;
}

a.link-select::after {
  content: "";
  display: block;
  width: 100%;
  -webkit-transform: ScaleX(0);
          transform: ScaleX(0);
  height: 2px;
  background: #86db55;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: 1;
}

a.link-select:hover::after {
  -webkit-transform: ScaleX(1);
          transform: ScaleX(1);
}

.link-select {
  color: #45C0F5;
  margin: 10px 0 10px 0;
  font-size: 14px;
}

.progress-title {
  font-size: 14px;
  color: #777777;
}

.progress {
  height: 10px !important;
}

.progress-bar {
  background-color: #45C0F5 !important;
}

.select-item-help {
  padding-left: 15px;
}
.select-item-help .btn-link {
  padding: 2px 2px 4px 2px;
  font-size: 14px;
}

/*# sourceMappingURL=main.css.map */

