#Camada_1 {
  height: 700px;
  width: 700px;
}
.brazil-map {
  fill: #AFD4E4;
  stroke: black;
}
/* #BR-RR {
  fill: #425380
}
#BR-AC {
  fill: #425380
}
#BR-RO {
  fill: #425380
}
#BR-MS {
  fill: #456386;
}
#BR-TO {
  fill: #425380;
}
#BR-PI {
  fill: #466587;
}
#BR-CE {
  fill: #4E8693;
}
#BR-PE {
  fill: #4E8693;
}
#BR-SE {
  fill: #456085;
}
#BR-AL {
  fill: #456085;
}
#BR-BA {
  fill: #52979A;
}
#BR-GO {
  fill: #4D8291;
} */
#BR-MG {
  fill: #597d98;
}
/* #BR-RJ {
  fill: #90C2CB;
}
#BR-ES {
  fill: #486B89;
} */
#BR-SP {
  fill: #884948
}
/* #BR-PR {
  fill: #52979A;
}
#BR-SC {
  fill: #4E8793;
}
#BR-RS {
  fill: #52979A;
} */

.graphic-map {
  height: 21px;
  background: #AFD4E4;
  background: linear-gradient(90deg, #AFD4E4, #486B89, #B22400);
}
.graphic-map-legends p:nth-child(1){
  margin-left: 0;
}
.graphic-map-legends p{
  margin-left: 16%;
}
.graphic-map-legends {
  display: flex;
}
.date-map {
  margin-top: 3%;
  display: flex;
  margin-left: 30%;
}
.date-map p:nth-child(1){
  margin-left: 0;
}
.date-map p {
  color: #cccccc;
  padding: 10px 0;
  margin-left: 2.5%;
  padding-right: 2.5%;
  border-right: 1px solid #CCCCCC;
}
.date-map p.active {
  color: #313131;
  cursor: default !important;
  background: #D2D2D2;
  margin-left: 0;
  border-left: 3px solid #666666;
  border-right: 3px solid #666666;
  padding-left: 2.5%;
}
.date-map p:hover {
  cursor: not-allowed;
}
